import React from 'react';
import LoggedInMainLayout from '../layout/LoggedInMainLayout';
import Profile from '../components/profile/Profile';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function AccountProfilePage() {
    usePageTitle('Replicant Trader | Account Profile');

    return (
        <div className="AccountProfile">
            <LoggedInMainLayout>
                <Profile />
            </LoggedInMainLayout>
        </div>
    );
}
