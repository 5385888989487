import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestoredb, storage } from '../../../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updatePassword, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { onSnapshot } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const fetchData = async (user) => {
    try {
      const userDocRef = doc(firestoredb, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        let userData = userDocSnapshot.data();

        // Check if profile picture exists
        const pictureRef = ref(storage, `users/profile_pictures/${user.uid}`);
        const pictureUrl = await getDownloadURL(pictureRef).catch(() => null); // Handle if picture does not exist

        // Add profile picture URL to userData if available
        if (pictureUrl) {
          userData = { ...userData, profilePictureUrl: pictureUrl };
        }

        // Fetch wallet balance
        const walletDocRef = doc(firestoredb, `wallet/${user.uid}`);
        onSnapshot(walletDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const walletBalance = docSnapshot.data().balance;
            // Update userData with updated wallet balance
            userData = { ...userData, walletBalance };
            setUserData(userData);
          }
        });

        // Update currentUser object with displayName fetched from Firestore
        setCurrentUser({
          ...user,
          displayName: userData.displayName,
          tradingRole: userData.tradingRole,
          phoneNumber: userData.phoneNumber,
          username: userData.username,
          profilePictureUrl: userData.profilePictureUrl || null, // Ensure profilePictureUrl is set or null
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        await fetchData(user);
      } else {
        setUserData(null);
      }
    });

    return unsubscribe;
  }, []);

  const signup = async (email, password, userInput) => {
    try {
      // Create user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);

      // Save data with "pending verification" status
      const tempUserRef = doc(firestoredb, 'pending_users', user.uid);
      await setDoc(tempUserRef, {
        displayName: `${userInput.firstName} ${userInput.lastName}`,
        tradingRole: userInput.tradingRole,
        createdAt: new Date(),
        phoneNumber: userInput.phoneNumber,
        username: userInput.username,
        picture: userInput.picture
          ? await uploadBytes(ref(storage, `users/profile_pictures/${user.uid}`), userInput.picture)
          : null,
        status: 'pending_verification',
      });

      // Sign out user
      await signOut(auth);

      // Display message to check email
      alert('A verification email has been sent to your email address. Please check your email and verify your account. After verification, close that tab and return to this page and wait for up to 1min to complete the signup process.');

      // Poll for email verification
      let isEmailVerified = false;
      while (!isEmailVerified) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        await user.reload();
        isEmailVerified = user.emailVerified;
      }

      // After verification, sign in the user again
      await signInWithEmailAndPassword(auth, email, password);
      const signedInUser = auth.currentUser;

      // Move data from pending_users to users
      const pendingUserRef = doc(firestoredb, 'pending_users', signedInUser.uid);
      const userRef = doc(firestoredb, 'users', signedInUser.uid);
      const pendingUserData = (await getDoc(pendingUserRef)).data();

      // Update status to "verified" before saving to "users" collection
      pendingUserData.status = 'verified';

      // Save updated data to "users" collection
      await setDoc(userRef, pendingUserData);
      await deleteDoc(pendingUserRef);

      // Create wallet data
      const walletRef = doc(firestoredb, `wallet/${signedInUser.uid}`);
      if (userInput.tradingRole === 'Market Maker') {
        await setDoc(walletRef, {
          balance: 0,
          createdAt: new Date(),
        });
      } else if (userInput.tradingRole === 'Client') {
        await setDoc(walletRef, {
          balance: 50000000,
          createdAt: new Date(),
          margin: 0,
        });
      }

      // Create transactions data
      const transactionsRef = doc(firestoredb, `transactions/${signedInUser.uid}`);
      if (userInput.tradingRole === 'Market Maker') {
        await setDoc(transactionsRef, {
          difference: 0,
          changedAt: new Date(),
        });
      } else if (userInput.tradingRole === 'Client') {
        await setDoc(transactionsRef, {
          difference: +50000000,
          createdAt: new Date(),
        });
      }

      await fetchData(signedInUser);

      // Redirect based on trading role
      if (userInput.tradingRole === 'Market Maker') {
        navigate('/mm-dashboard');
      } else if (userInput.tradingRole === 'Client') {
        navigate('/client');
      }

      return signedInUser;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email address is already in use.');
      } else {
        console.error('Signup Error:', error.message);
        throw error;
      }
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the email is verified
      if (!user.emailVerified) {
        // Send a new verification email if not verified
        await sendEmailVerification(user);
        alert('Please verify your email before logging in. A verification email has been sent to your email address. Please verify your email address before logging in.');

        // Sign out the user since they need to verify first
        await signOut(auth);
        return;
      }

      // Fetch the user document from Firestore
      const userDoc = await getDoc(doc(firestoredb, 'users', user.uid));
      if (!userDoc.exists()) {
        throw new Error('User data not found in the database.');
      }
      const userData = userDoc.data();

      // Get the profile picture URL from Firebase Storage
      const pictureRef = ref(storage, `users/profile_pictures/${user.uid}`);
      const pictureUrl = await getDownloadURL(pictureRef);

      // Combine user data with the picture URL
      const userInfo = { ...userData, pictureUrl };

      // Redirect based on trading role
      if (userInfo.tradingRole === 'Market Maker') {
        navigate('/mm-dashboard');
      } else if (userInfo.tradingRole === 'Client') {
        navigate('/client');
      }

      return user;
    } catch (error) {
      console.error('Login Error:', error.message);
      throw error;
    }
  };

  const logout = () => {
    auth.signOut()
      .then(() => {
        localStorage.clear();
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const updateProfilePicture = async (file) => {
    if (!currentUser) return;

    const pictureRef = ref(storage, `users/profile_pictures/${currentUser.uid}`);
    await uploadBytes(pictureRef, file);
    const pictureUrl = await getDownloadURL(pictureRef);

    const userRef = doc(firestoredb, 'users', currentUser.uid);
    await updateDoc(userRef, { profilePictureUrl: pictureUrl });

    await fetchData(currentUser);
  };

  const updateUserProfile = async (displayName, username, phoneNumber) => {
    if (!currentUser) return;

    const userRef = doc(firestoredb, 'users', currentUser.uid);
    await updateDoc(userRef, {
      displayName: displayName,
      username: username,
      phoneNumber: phoneNumber,
    });

    await fetchData(currentUser);
  };

  // Function to update password
  const updatePasswordInAuthProvider = async (currentPassword, newPassword) => {
    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User is not authenticated');
      }

      // Reauthenticate user
      const credentials = await signInWithEmailAndPassword(auth, user.email, currentPassword);
      if (!credentials) {
        throw new Error('Current password is incorrect');
      }

      // Update password
      await updatePassword(user, newPassword);

      // console.log('Password updated successfully!');
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    userData,
    signup,
    login,
    logout,
    updateProfilePicture,
    updateUserProfile,
    updatePassword: updatePasswordInAuthProvider
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
