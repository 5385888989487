import { Timestamp } from 'firebase/firestore';

// Function to convert Firestore Timestamp to JavaScript Date
const convertTimestampToDate = (timestamp) => {
    return timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
};

// Function to calculate the remaining time for each quote
const calculateRemainingTime = (createdAt, validFor) => {
    const now = new Date();
    const expiryTime = new Date(createdAt.getTime() + validFor * 1000); // validFor is in seconds
    return Math.max(0, Math.floor((expiryTime - now) / 1000));
};

export { convertTimestampToDate, calculateRemainingTime };
