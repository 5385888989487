import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, MenuItem, CircularProgress, FormControl, InputLabel, ThemeProvider } from '@mui/material';
import defaultTheme from '../../utilities/styleUtilities/DefaultTheme';

const CACHE_DURATION_MS = 3600000; // 1 hour in milliseconds

const News = () => {
    const [newsData, setNewsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [country, setCountry] = useState(localStorage.getItem('selectedCountry') || 'ke'); // Load from localStorage or default to 'ke'
    const [category, setCategory] = useState(localStorage.getItem('selectedCategory') || 'business'); // Load from localStorage or default to 'business'
    const [selectedArticle, setSelectedArticle] = useState(JSON.parse(localStorage.getItem('selectedArticle')) || null); // Load from localStorage
    const [modalOpen, setModalOpen] = useState(JSON.parse(localStorage.getItem('modalOpen')) || false); // Load from localStorage

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                // console.log('Fetching news data...');
                const cacheKey = `${country}-${category}`;
                const cachedData = localStorage.getItem(cacheKey);
                const now = new Date().getTime();

                if (cachedData) {
                    const { timestamp, data } = JSON.parse(cachedData);
                    if (now - timestamp < CACHE_DURATION_MS) {
                        // Use cached data if it's still valid
                        setNewsData(data);
                        setLoading(false);
                        return;
                    }
                }

                // Fetch new data if cache is not valid
                const response = await axios.get(`https://newsdata.io/api/1/latest?country=${country}&category=${category}&apikey=pub_5256254942968368c5ed88bc3f7053f272806`);
                // console.log('API response received:', response);
                setNewsData(response.data);
                localStorage.setItem(cacheKey, JSON.stringify({ timestamp: now, data: response.data }));
                setLoading(false);
            } catch (err) {
                console.error('Error fetching news data:', err);
                setError('Failed to fetch news data. Please try again later.');
                setLoading(false);
            }
        };

        fetchNewsData();
    }, [country, category]); // Refetch news when country or category changes

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry);
        localStorage.setItem('selectedCountry', selectedCountry); // Cache the selected country
    };

    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);
        localStorage.setItem('selectedCategory', newCategory); // Cache the selected category
    };

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
        setModalOpen(true);
        localStorage.setItem('selectedArticle', JSON.stringify(article)); // Cache the selected article
        localStorage.setItem('modalOpen', JSON.stringify(true)); // Cache the modal open state
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedArticle(null);
        localStorage.setItem('modalOpen', JSON.stringify(false)); // Cache the modal closed state
        localStorage.removeItem('selectedArticle'); // Remove the cached article when modal is closed
    };

    if (loading) return <CircularProgress />;
    if (error) return <p>{error}</p>;

    // Inline styles
    const styles = {
        container: {
            textAlign: 'left',
            padding: '5px',
        },
        h1: {
            margin: '0',
            padding: '0',
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3px',
        },
        buttons: {
            display: 'flex',
            gap: '10px', // Adds space between buttons
            marginLeft: '10px', // Adds space between select and buttons
        },
        list: {
            maxHeight: '280px',
            overflowY: 'auto',
            padding: '0',
            margin: '0',
            listStyle: 'none',
            textAlign: 'left',
            scrollbarWidth: 'thin',
            scrollbarColor: '#D9D9D9 #112240',
        },
        listItem: {
            marginBottom: '10px',
            cursor: 'pointer',
        },
        button: {
            margin: '2px',
            padding: '5px 10px',
            cursor: 'pointer',
            border: '1px solid #ccc',
            borderRadius: '5px',
            background: '#9c27b0',
        },
        activeButton: {
            background: '#007bff',
            color: '#fff',
            border: 'none',
        },
        modal: {
            display: modalOpen ? 'flex' : 'none',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
        },
        modalContent: {
            background: '#0a192f',
            padding: '20px',
            borderRadius: '5px',
            width: '80%',
            maxWidth: '600px',
            position: 'relative',
            color: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        modalBody: {
            maxHeight: '300px', // Set maximum height for the description section
            overflowY: 'auto', // Enable vertical scrolling if content exceeds max height
            marginBottom: '10px',
        },
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            background: '#f00',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            padding: '5px',
        },
        readFullStoryButton: {
            display: 'block',
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        date: {
            margin: '5px 0',
            fontStyle: 'italic',
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <div style={styles.container}>
                <h1 style={styles.h1}>Latest News</h1>

                {/* Controls - Country Selection and Category Buttons */}
                <div style={styles.controls}>
                    <FormControl sx={{ minWidth: 120, height: 40 }}>
                        <InputLabel sx={{ top: -6 }}>Country</InputLabel>
                        <Select
                            value={country}
                            onChange={handleCountryChange}
                            label="Country"
                            sx={{ height: 40, paddingTop: '8px', paddingBottom: '8px' }}
                        >
                            <MenuItem value='ke'>Kenya</MenuItem>
                            <MenuItem value='us'>US</MenuItem>
                            {/* Add more countries as needed */}
                        </Select>
                    </FormControl>

                    <div style={styles.buttons}>
                        <button
                            style={{ ...styles.button, ...(category === 'business' ? styles.activeButton : {}) }}
                            onClick={() => handleCategoryChange('business')}
                        >
                            Business
                        </button>
                        <button
                            style={{ ...styles.button, ...(category === 'technology' ? styles.activeButton : {}) }}
                            onClick={() => handleCategoryChange('technology')}
                        >
                            Technology
                        </button>
                        {/* Add more categories as needed */}
                    </div>
                </div>

                <ul style={styles.list}>
                    {newsData?.results.map((article, index) => (
                        <li key={index} style={styles.listItem} onClick={() => handleArticleClick(article)}>
                            {article.title}
                        </li>
                    ))}
                </ul>

                {/* Modal */}
                {modalOpen && selectedArticle && (
                    <div
                        style={styles.modal}
                        onClick={handleCloseModal} // Close modal when clicking outside the content
                    >
                        <div
                            style={styles.modalContent}
                            onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                        >
                            <button style={styles.closeButton} onClick={handleCloseModal}>X</button>
                            <h2>{selectedArticle.title}</h2>
                            <div style={styles.date}>
                                <p>Published on: {new Date(selectedArticle.pubDate).toLocaleString()} {selectedArticle.pubDateTZ}</p>
                            </div>
                            <div style={styles.modalBody}>
                                <p>{selectedArticle.description}</p>
                            </div>
                            <button
                                style={styles.readFullStoryButton}
                                onClick={() => window.open(selectedArticle.link, '_blank')}
                            >
                                Read Full Story
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default News;
