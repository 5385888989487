import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import NotLoggedInMainLayout from '../layout/NotLoggedInMainLayout';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function LandingPage() {
  usePageTitle('Replicant Trader | Home');

  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/sign-up");
  };

  return (
    <div className="LandingPage">
      <NotLoggedInMainLayout>
      </NotLoggedInMainLayout>
      <div className="body">
        <h1 className="landing-text">Welcome to Replicant Trader</h1>
        <p className="description-text">
          Step into the world of trading with a platform designed for both beginners and pros.
          Replicant Trader offers a seamless, realistic trading simulation experience across NSE and NYSE markets.
          Ready to sharpen your trading skills, test strategies, and learn from the best?
        </p>
        <button className="get-started-button" onClick={handleGetStartedClick}>
          Get Started
        </button>
      </div>
    </div>
  );
}
