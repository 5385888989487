import React from 'react';
import LoggedInMainLayout from '../layout/LoggedInMainLayout';
import Settings from '../components/settings/Settings';
// import './AccountSettingsPage.css';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function AccountSettingsPage() {
    usePageTitle('Replicant Trader | Account Settings');

    return (
        <div className="AccountSettings">
            <LoggedInMainLayout>
                <Settings />
            </LoggedInMainLayout>
        </div>
    );
}
