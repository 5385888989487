import React from 'react';
import './InfoPages.css';
import NotLoggedInMainLayout from '../layout/NotLoggedInMainLayout';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function WhatWeDoPage() {
  usePageTitle('Replicant Trader | What We Do');

  return (
    <div className="InfoPage">
      <NotLoggedInMainLayout>
        <div className="body">
          <h1 className="page-title">What We Do</h1>
          <p className="page-description">
            At Replicant Trader, we empower users to develop trading strategies, simulate market conditions, and experience the dynamics of real-time trading, all in a risk-free environment. Our platform fosters learning and confidence for future traders.
          </p>
        </div>
      </NotLoggedInMainLayout>
    </div>
  );
}
