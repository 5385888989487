import React, { useRef, useState, useEffect } from 'react';
import { createChart } from 'lightweight-charts';
import { Box, FormControl, InputLabel, MenuItem, Select, CircularProgress, Typography } from '@mui/material';
import Papa from 'papaparse';
import Container from '@mui/material/Container';

// Function to convert date to timestamp
const convertToTimestamp = (dateString) => {
    if (!dateString) {
        console.error("Invalid date:", dateString);
        return NaN;  // Return NaN for invalid dates
    }

    try {
        // Handle both date formats: "MM DD YYYY" for day-level and "MM DD YYYY HH:MM" for hour/minute
        const [month, day, year, time] = dateString.split(' ');
        const formattedDate = `${year}-${month}-${day}${time ? 'T' + time + ':00' : ''}`;  // "YYYY-MM-DDTHH:MM:SS" format
        const date = new Date(formattedDate);

        if (!isNaN(date.getTime())) {
            return Math.floor(date.getTime() / 1000);  // Return timestamp in seconds
        } else {
            console.error("Date parsing failed:", dateString);
            return NaN;
        }
    } catch (error) {
        console.error("Error converting date:", dateString, error);
        return NaN;
    }
};

export default function CustomDataChart() {
    const [data, setData] = useState([]);
    const [tickerName, setTickerName] = useState(''); // State for ticker name
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [interval, setInterval] = useState('day');
    const [chartType, setChartType] = useState('candlestick');
    const chartContainerRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const lineSeriesRef = useRef(null);
    const candlestickSeriesRef = useRef(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setData([]);  // Clear current data before loading new CSV
        setTickerName('');  // Clear ticker name before loading new CSV
        setError(null);  // Clear previous error state
        setIsLoading(true);  // Show loading spinner

        // Remove existing series if any
        if (chartInstanceRef.current) {
            if (lineSeriesRef.current) {
                chartInstanceRef.current.removeSeries(lineSeriesRef.current);
                lineSeriesRef.current = null;
            }
            if (candlestickSeriesRef.current) {
                chartInstanceRef.current.removeSeries(candlestickSeriesRef.current);
                candlestickSeriesRef.current = null;
            }
        }

        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
                const csvData = results.data;
                const ticker = csvData[0]?.Ticker || 'Unknown Ticker';
                setTickerName(ticker);

                const parsedData = csvData.map(row => ({
                    time: convertToTimestamp(row.Date),
                    open: row.Open,
                    high: row.High,
                    low: row.Low,
                    close: row.Close,
                    value: row.Close
                }));
                const filteredData = parsedData.filter(row => !isNaN(row.time));
                const sortedData = filteredData.sort((a, b) => a.time - b.time);

                setData(sortedData);

                // Cache data, tickerName, chart type, and interval
                localStorage.setItem('chartData', JSON.stringify(sortedData));
                localStorage.setItem('chartType', chartType);
                localStorage.setItem('interval', interval);
                localStorage.setItem('tickerName', ticker);
                localStorage.setItem('selectedFileName', file.name);

                setIsLoading(false);
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
                setError('Error parsing the CSV file. Please try again.');
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        const cachedData = localStorage.getItem('chartData');
        const cachedChartType = localStorage.getItem('chartType');
        const cachedInterval = localStorage.getItem('interval');
        const cachedTickerName = localStorage.getItem('tickerName');

        if (cachedData) {
            setData(JSON.parse(cachedData));
        }
        if (cachedChartType) {
            setChartType(cachedChartType);
        }
        if (cachedInterval) {
            setInterval(cachedInterval);
        }
        if (cachedTickerName) {
            setTickerName(cachedTickerName);
        }
    }, []);

    useEffect(() => {
        const updateChartSize = () => {
            if (chartContainerRef.current && chartInstanceRef.current) {
                const { clientWidth, clientHeight } = chartContainerRef.current;
                chartInstanceRef.current.applyOptions({
                    width: clientWidth,
                    height: clientHeight,
                });
            }
        };

        if (data.length && chartContainerRef.current && !chartInstanceRef.current) {
            chartInstanceRef.current = createChart(chartContainerRef.current, {
                layout: { backgroundColor: '#FFFFFF' },
                timeScale: {
                    timeVisible: interval !== 'day',
                    secondsVisible: interval === 'minute',
                },
                rightPriceScale: { borderColor: '#cccccc' },
                grid: { vertLines: { color: '#e0e0e0' }, horzLines: { color: '#e0e0e0' } },
                crosshair: { mode: 1 },
                priceLineVisible: true,
            });

            window.addEventListener('resize', updateChartSize);
            updateChartSize();

            return () => {
                if (chartInstanceRef.current) {
                    chartInstanceRef.current.remove();
                    chartInstanceRef.current = null;
                }
            };
        }
    }, [data, interval]);

    useEffect(() => {
        if (chartInstanceRef.current) {
            if (lineSeriesRef.current) {
                chartInstanceRef.current.removeSeries(lineSeriesRef.current);
                lineSeriesRef.current = null;
            }
            if (candlestickSeriesRef.current) {
                chartInstanceRef.current.removeSeries(candlestickSeriesRef.current);
                candlestickSeriesRef.current = null;
            }

            if (chartType === 'line') {
                lineSeriesRef.current = chartInstanceRef.current.addLineSeries({
                    color: '#2962FF',
                    lineWidth: 2,
                });
                lineSeriesRef.current.setData(data.map(d => ({ time: d.time, value: d.value })));
            } else if (chartType === 'candlestick') {
                candlestickSeriesRef.current = chartInstanceRef.current.addCandlestickSeries({
                    upColor: '#26a69a',
                    downColor: '#ef5350',
                    borderVisible: true,
                    wickUpColor: '#26a69a',
                    wickDownColor: '#ef5350',
                });
                candlestickSeriesRef.current.setData(data.map(d => ({
                    time: d.time,
                    open: d.open,
                    high: d.high,
                    low: d.low,
                    close: d.close,
                })));
            }
        }
    }, [data, chartType]);

    const handleIntervalChange = (event) => {
        setInterval(event.target.value);
    };

    const handleChartTypeChange = (event) => {
        setChartType(event.target.value);
    };

    return (
        <Container maxWidth="md" sx={{ padding: '0 16px' }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    style={{ marginBottom: '10px' }}
                />
                <FormControl size="small" sx={{ mr: 1, minWidth: 120 }}>
                    <InputLabel id="interval-select-label">Interval</InputLabel>
                    <Select
                        labelId="interval-select-label"
                        value={interval}
                        onChange={handleIntervalChange}
                        label="Interval"
                    >
                        <MenuItem value="minute">Minute</MenuItem>
                        <MenuItem value="hour">Hour</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{ mr: 1, minWidth: 120 }}>
                    <InputLabel id="chart-type-select-label">Chart Type</InputLabel>
                    <Select
                        labelId="chart-type-select-label"
                        value={chartType}
                        onChange={handleChartTypeChange}
                        label="Chart Type"
                    >
                        <MenuItem value="line">Line</MenuItem>
                        <MenuItem value="candlestick">Candlestick</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Typography color="error">{error}</Typography>
                </Box>
            )}
            <Box ref={chartContainerRef} sx={{ height: 240, mt: 0 }} />
        </Container>
    );
}
