import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper, Button, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { firestoredb } from "../../../firebase";
import { collection, doc, updateDoc, getDoc, setDoc, addDoc } from "firebase/firestore";
import { useAuth } from '../../authentication/authContext/AuthContext';
import { calculateRemainingTime } from '../../utilities/clientQuote/Time';
import TransactionsList from '../../utilities/clientQuote/TransactionsList';
import QuotesFetcher from '../../utilities/clientQuote/QuotesFetcher';
import QuoteExpirationHandler from '../../utilities/clientQuote/QuoteExpirationHandler';
import PositionsFetcher from '../../utilities/clientQuote/PositionsFetcher';
import { nseStockData } from '../../utilities/dataSource/NSEStockData';
import { fetchExchangeRate } from '../../utilities/exchange/currencyExchangeRate';

export default function QuotesTable() {
    const { currentUser } = useAuth();
    const [alignment, setAlignment] = useState(() => {
        const savedAlignment = localStorage.getItem('alignment');
        return savedAlignment ? JSON.parse(savedAlignment) : 'liveQuotes';
    });
    const [liveQuotes, setLiveQuotes] = useState([]);
    const [oldQuotes, setOldQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [positions, setPositions] = useState([]);

    const handleToggleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    // Save the alignment state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('alignment', JSON.stringify(alignment));
    }, [alignment]);

    // Helper function to update wallet balance
    const updateWalletBalance = async (walletDocRef, newBalance, margin = null) => {
        const updateData = {
            balance: newBalance,
            createdAt: new Date()
        };
        if (margin !== null) {
            updateData.margin = margin;
        }
        await updateDoc(walletDocRef, updateData);
    };

    const updatePosition = async (positionDocRef, newPosition, newPrice = null) => {
        const updateData = {
            position: newPosition,
            createdAt: new Date()
        };
        if (newPrice !== null) {
            updateData.buyPrice = newPrice; // Store the latest price
        }
        await updateDoc(positionDocRef, updateData);
    };

    const createTransaction = async (transactionsCollectionRef, moneyDifference, positionsDifference) => {
        const newTransaction = {
            moneyDifference,
            positionsDifference,
            createdAt: new Date(),
        };
        await addDoc(transactionsCollectionRef, newTransaction);
    };

    const handleBuy = async (quote) => {
        const { volume, offer, id, stockTicker, creatorUserUId } = quote;
        const isNseStock = nseStockData.some(stock => stock.ticker === stockTicker);

        let transactionAmount = offer * volume;
        let feeRate = 0.013; // 1.3%
        let fee = transactionAmount * feeRate; // Calculate the fee

        // Convert to USD if the stock is in the NSE list
        if (isNseStock) {
            try {
                const exchangeRate = await fetchExchangeRate();
                transactionAmount *= exchangeRate; // Convert amount to USD
                fee *= exchangeRate; // Convert fee to USD
            } catch (error) {
                console.error('Error fetching exchange rate:', error);
                // Handle the error appropriately, maybe fallback to some default behavior
            }
        }

        const positionDocRef = doc(firestoredb, 'positions', currentUser.uid, 'stocks', stockTicker);
        const walletDocRef = doc(firestoredb, 'wallet', currentUser.uid);
        const marketMakerWalletRef = doc(firestoredb, 'wallet', creatorUserUId);
        const marketMakerPositionDocRef = doc(firestoredb, 'positions', creatorUserUId, 'stocks', stockTicker);

        try {
            const [positionSnapshot, walletSnapshot, marketMakerWalletSnapshot, marketMakerPositionSnapshot] = await Promise.all([
                getDoc(positionDocRef),
                getDoc(walletDocRef),
                getDoc(marketMakerWalletRef),
                getDoc(marketMakerPositionDocRef)
            ]);

            let currentPosition = positionSnapshot.exists() ? Number(positionSnapshot.data().position) : 0;
            let currentBuyPrice = positionSnapshot.exists() ? Number(positionSnapshot.data().buyPrice) : offer; // Get the average buy price if it exists
            let newBalance = walletSnapshot.data().balance;
            let margin = walletSnapshot.data().margin || 0;
            let marketMakerBalance = marketMakerWalletSnapshot.data().balance;
            let marketMakerPosition = marketMakerPositionSnapshot.exists() ? Number(marketMakerPositionSnapshot.data().position) : 0;

            if (currentPosition < 0 && margin > 0) { // Buying back a short position
                if (!positionSnapshot.exists()) {
                    // Create the document if it does not exist
                    await setDoc(positionDocRef, {
                        position: volume, // Set position to the volume being bought
                        shortPrice: offer, // Set the price at which the stock was shorted
                        createdAt: new Date()
                    });
                    currentPosition = volume; // Set currentPosition to volume
                } else {
                    const shortPrice = positionSnapshot.data().shortPrice;
                    const profitOrLoss = (shortPrice - offer) * Math.abs(currentPosition); // Use absolute value of currentPosition
                    newBalance += (margin + profitOrLoss);
                    margin = 0;
                    currentPosition += volume;

                    // Calculate the new average buy price
                    const totalAmount = currentBuyPrice * Math.abs(currentPosition - volume) + offer * volume;
                    const totalVolume = Math.abs(currentPosition);
                    currentBuyPrice = totalAmount / totalVolume;

                    // Update the position document with the new position and the buy price
                    await updateDoc(positionDocRef, {
                        position: currentPosition,
                        buyPrice: currentBuyPrice // Update the average buy price
                    });
                }

                // Update market maker’s position to reflect that they sold stock to the user
                if (marketMakerPositionSnapshot.exists()) {
                    marketMakerPosition -= volume; // Decrease market maker’s position
                } else {
                    marketMakerPosition = -volume; // Initialize market maker’s position if not existing
                }

                marketMakerBalance += fee;
                await updateDoc(doc(firestoredb, 'quotes', currentUser.uid, 'data', id), { status: 'bought back' });
            } else if (newBalance < (transactionAmount + fee)) { // Insufficient balance (including fee)
                alert('Insufficient balance to buy this financial instrument.');
                return;
            } else { // Normal buy case
                if (!positionSnapshot.exists()) {
                    // Create the document if it does not exist
                    await setDoc(positionDocRef, {
                        position: volume, // Initialize position with volume
                        buyPrice: offer, // Store the price at which the stock was bought
                        createdAt: new Date()
                    });
                    currentPosition = volume; // Initialize currentPosition to volume
                    currentBuyPrice = offer; // Initialize currentBuyPrice to offer
                } else {
                    // Update the existing position document
                    currentPosition += volume;

                    // Calculate the new average buy price
                    const totalAmount = currentBuyPrice * currentPosition + offer * volume;
                    const totalVolume = currentPosition + volume;
                    currentBuyPrice = totalAmount / totalVolume;

                    await updateDoc(positionDocRef, {
                        position: currentPosition,
                        buyPrice: currentBuyPrice // Update the average buy price
                    });
                }

                newBalance -= (transactionAmount + fee); // Deduct the fee as well
                marketMakerBalance += fee;
                marketMakerPosition -= volume;
                await updateDoc(doc(firestoredb, 'quotes', currentUser.uid, 'data', id), { status: 'bought' });
            }

            // Update market maker’s position document
            if (!marketMakerPositionSnapshot.exists()) {
                await setDoc(marketMakerPositionDocRef, {
                    position: marketMakerPosition, // Set the market maker's position
                    createdAt: new Date()
                });
            } else {
                await updateDoc(marketMakerPositionDocRef, { position: marketMakerPosition });
            }

            await Promise.all([
                updateWalletBalance(walletDocRef, newBalance, margin),
                updateWalletBalance(marketMakerWalletRef, marketMakerBalance),
                updatePosition(positionDocRef, currentPosition, currentBuyPrice),
                updatePosition(marketMakerPositionDocRef, marketMakerPosition)
            ]);

            const transactionsCollectionRef = collection(firestoredb, 'transactions', currentUser.uid, 'transactionDetails');
            const marketMakerTransactionsCollectionRef = collection(firestoredb, 'transactions', creatorUserUId, 'transactionDetails');

            await Promise.all([
                createTransaction(transactionsCollectionRef, -transactionAmount - fee, volume), // Deduct total cost including fee
                createTransaction(marketMakerTransactionsCollectionRef, fee, -volume) // Market maker receives the fee
            ]);
        } catch (error) {
            console.error('Error handling buy transaction:', error);
        }
    };

    const handleSell = async (quote) => {
        const { volume, bid, id, stockTicker, creatorUserUId } = quote;
        const isNseStock = nseStockData.some(stock => stock.ticker === stockTicker);

        let transactionAmount = bid * volume;
        let feeRate = 0.013; // 1.3%
        let fee = transactionAmount * feeRate; // Calculate the fee

        // Convert to USD if the stock is in the NSE list
        if (isNseStock) {
            try {
                const exchangeRate = await fetchExchangeRate();
                transactionAmount *= exchangeRate; // Convert amount to USD
                fee *= exchangeRate; // Convert fee to USD
            } catch (error) {
                console.error('Error fetching exchange rate:', error);
                // Handle the error appropriately, maybe fallback to some default behavior
            }
        }

        const positionDocRef = doc(firestoredb, 'positions', currentUser.uid, 'stocks', stockTicker);
        const walletDocRef = doc(firestoredb, 'wallet', currentUser.uid);
        const marketMakerWalletRef = doc(firestoredb, 'wallet', creatorUserUId);
        const marketMakerPositionDocRef = doc(firestoredb, 'positions', creatorUserUId, 'stocks', stockTicker);

        try {
            const [positionSnapshot, walletSnapshot, marketMakerWalletSnapshot, marketMakerPositionSnapshot] = await Promise.all([
                getDoc(positionDocRef),
                getDoc(walletDocRef),
                getDoc(marketMakerWalletRef),
                getDoc(marketMakerPositionDocRef)
            ]);

            let newBalance = walletSnapshot.data().balance;
            let margin = walletSnapshot.data().margin || 0;
            let currentPosition = positionSnapshot.exists() ? Number(positionSnapshot.data().position) : 0;
            let currentBuyPrice = positionSnapshot.exists() ? Number(positionSnapshot.data().buyPrice) : 0;
            let shortPrice = positionSnapshot.exists() ? Number(positionSnapshot.data().shortPrice) : bid; // Existing short price
            let marketMakerBalance = marketMakerWalletSnapshot.data().balance;
            let marketMakerPosition = marketMakerPositionSnapshot.exists() ? Number(marketMakerPositionSnapshot.data().position) : 0;

            if (transactionAmount > newBalance) {
                alert('Insufficient balance to short this financial instrument.');
                return;
            }

            if (currentPosition === 0) { // Creating a new short position
                margin += transactionAmount; // Increase margin
                newBalance -= transactionAmount; // Deduct the amount for the short position

                // Set the user's position to -volume for shorting
                currentPosition = -volume;

                // Create or update the short position document for the user
                await setDoc(positionDocRef, {
                    position: currentPosition, // Set the position to negative for shorting
                    shortPrice: bid,
                    createdAt: new Date()
                });

                // Update or create the market maker's position
                if (marketMakerPositionSnapshot.exists()) {
                    marketMakerPosition += volume; // Increase market maker’s position
                } else {
                    marketMakerPosition = volume; // Initialize market maker’s position
                    await setDoc(marketMakerPositionDocRef, {
                        position: marketMakerPosition,
                        createdAt: new Date()
                    });
                }

                marketMakerBalance += fee; // Market maker receives the fee

                await updateDoc(doc(firestoredb, 'quotes', currentUser.uid, 'data', id), { status: 'short' });
            } else if (currentPosition < 0) { // Extending an existing short position
                margin += transactionAmount; // Increase margin
                newBalance -= transactionAmount; // Deduct the amount for the short position

                // Update the position to reflect the new short volume
                currentPosition -= volume; // Since it's a short position, subtract the volume

                // Update the short price to reflect the new transaction
                let newShortPrice = ((shortPrice * Math.abs(currentPosition + volume)) + (bid * volume)) / Math.abs(currentPosition);

                // Update the position document with the new short position and price
                await updateDoc(positionDocRef, {
                    position: currentPosition,
                    shortPrice: newShortPrice, // Update the short price to the weighted average
                    createdAt: new Date()
                });

                // Update market maker's position
                marketMakerPosition += volume; // Increase market maker’s position
                marketMakerBalance += fee; // Market maker receives the fee

                await updateDoc(doc(firestoredb, 'quotes', currentUser.uid, 'data', id), { status: 'short' });
            } else { // Normal sell
                newBalance += (transactionAmount - fee); // Add transaction amount to wallet, minus the fee
                currentPosition -= volume; // Decrease the current position

                if (currentPosition <= 0) {
                    currentBuyPrice = 0; // If all positions sold, reset buyPrice
                }

                await updateDoc(positionDocRef, {
                    position: currentPosition,
                    buyPrice: currentBuyPrice
                });

                // Update market maker's position
                marketMakerPosition += volume; // Increase market maker’s position
                marketMakerBalance += fee; // Market maker receives the fee

                await updateDoc(doc(firestoredb, 'quotes', currentUser.uid, 'data', id), { status: 'sold' });
            }

            await Promise.all([
                updateWalletBalance(walletDocRef, newBalance, margin),
                updateWalletBalance(marketMakerWalletRef, marketMakerBalance),
                updatePosition(positionDocRef, currentPosition, currentBuyPrice),
                updatePosition(marketMakerPositionDocRef, marketMakerPosition)
            ]);

            const transactionsCollectionRef = collection(firestoredb, 'transactions', currentUser.uid, 'transactionDetails');
            const marketMakerTransactionsCollectionRef = collection(firestoredb, 'transactions', creatorUserUId, 'transactionDetails');

            await Promise.all([
                createTransaction(transactionsCollectionRef, transactionAmount - fee, -volume), // Deduct transaction amount and fee
                createTransaction(marketMakerTransactionsCollectionRef, fee, volume) // Market maker receives the fee
            ]);
        } catch (error) {
            console.error('Error handling sell transaction:', error);
        }
    };

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                width: 600,
                position: 'relative',
                minHeight: 100,
            }}
        >
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleToggleChange}
                aria-label="Quotes"
            >
                <ToggleButton value="liveQuotes">Live Quotes</ToggleButton>
                <ToggleButton value="oldQuotes">Old Quotes</ToggleButton>
                <ToggleButton value="transactions">Transactions</ToggleButton>
                <ToggleButton value="positions">Positions</ToggleButton>
            </ToggleButtonGroup>

            <QuotesFetcher
                currentUser={currentUser}
                setLiveQuotes={setLiveQuotes}
                setOldQuotes={setOldQuotes}
                setLoading={setLoading}
            />

            <QuoteExpirationHandler
                liveQuotes={liveQuotes}
                setLiveQuotes={setLiveQuotes}
                setOldQuotes={setOldQuotes}
                currentUser={currentUser}
            />

            <PositionsFetcher
                currentUser={currentUser}
                alignment={alignment}
                setPositions={setPositions}
            />

            {loading && <CircularProgress />}

            {alignment === 'liveQuotes' && (
                <TableContainer component={Paper} sx={{ maxHeight: 330, overflowY: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#1976D2', borderRadius: '4px' }, '&::-webkit-scrollbar-track': { backgroundColor: '#D9D9D9' } }}>
                    <Table sx={{ maxWidth: 500 }} size="small" aria-label="live-quotes-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">SECURITY</TableCell>
                                <TableCell align="left">VOLUME</TableCell>
                                <TableCell align="left">BUY</TableCell>
                                <TableCell align="left">SELL</TableCell>
                                <TableCell align="left">VALID FOR</TableCell>
                                <TableCell align="left">MARKET MAKER</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {liveQuotes.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.stockTicker}</TableCell>
                                    <TableCell align="left">{row.volume}</TableCell>
                                    <TableCell align="left">
                                        <Button variant="contained" color="primary" size="small" onClick={() => handleBuy(row)}>
                                            {row.offer}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button variant="contained" color="secondary" size="small" onClick={() => handleSell(row)}>
                                            {row.bid}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">{calculateRemainingTime(row.createdAt, row.validFor)}s</TableCell>
                                    <TableCell align="left">{row.createdBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {alignment === 'oldQuotes' && (
                <TableContainer component={Paper} sx={{ maxHeight: 330, overflowY: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#1976D2', borderRadius: '4px' }, '&::-webkit-scrollbar-track': { backgroundColor: '#D9D9D9' } }}>
                    <Table sx={{ maxWidth: 500 }} size="small" aria-label="old-quotes-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">SECURITY</TableCell>
                                <TableCell align="left">VOLUME</TableCell>
                                <TableCell align="left">BUY</TableCell>
                                <TableCell align="left">SELL</TableCell>
                                <TableCell align="left">STATUS</TableCell>
                                <TableCell align="left">MARKET MAKER</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {oldQuotes.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.stockTicker}</TableCell>
                                    <TableCell align="left">{row.volume}</TableCell>
                                    <TableCell align="left">{row.offer}</TableCell>
                                    <TableCell align="left">{row.bid}</TableCell>
                                    <TableCell align="left">{row.status}</TableCell>
                                    <TableCell align="left">{row.createdBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {alignment === 'transactions' && (
                <TransactionsList userId={currentUser.uid} />
            )}

            {alignment === 'positions' && (
                <TableContainer component={Paper} sx={{ maxHeight: 330, overflowY: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#1976D2', borderRadius: '4px' }, '&::-webkit-scrollbar-track': { backgroundColor: '#D9D9D9' } }}>
                    <Table sx={{ maxWidth: 500 }} size="small" aria-label="positions-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Stock Ticker</TableCell>
                                <TableCell align="left">Position</TableCell>
                                <TableCell align="left">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {positions.length > 0 ? (
                                positions.map((position) => (
                                    <TableRow key={position.id}>
                                        <TableCell align="left">{position.stockTicker}</TableCell>
                                        <TableCell align="left">{position.position}</TableCell>
                                        <TableCell align="left">{position.createdAt}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">No Positions Available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}
