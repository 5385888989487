const timeLabelCache = new Map();

function getStartOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function calculateTimeLabel(timestamp) {
  const messageDate = getStartOfDay(new Date(timestamp));
  const today = getStartOfDay(new Date());

  const diffDays = (today - messageDate) / (1000 * 60 * 60 * 24);

  if (diffDays === 0) {
    return 'Today';
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else if (diffDays < 7) {
    return messageDate.toLocaleDateString('en-US', { weekday: 'long' });
  } else {
    return messageDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }
}

function getTimeLabel(timestamp) {
  if (timeLabelCache.has(timestamp)) {
    return timeLabelCache.get(timestamp);
  } else {
    const label = calculateTimeLabel(timestamp);
    timeLabelCache.set(timestamp, label);
    return label;
  }
}

function scheduleCacheInvalidation() {
  const now = new Date();
  const nextMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
  const timeUntilMidnight = nextMidnight - now;

  setTimeout(() => {
    timeLabelCache.clear();
    scheduleCacheInvalidation();
  }, timeUntilMidnight);
}

scheduleCacheInvalidation();

export { timeLabelCache, getStartOfDay, calculateTimeLabel, getTimeLabel, scheduleCacheInvalidation };