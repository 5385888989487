export default function twentyFourHourTimeFormat(timestamp) {
    const date = new Date(timestamp);

    // Format time according to the user's local timezone
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format; set to true for 12-hour format with AM/PM
    };

    // Use toLocaleTimeString to get the time in local timezone
    return date.toLocaleTimeString(undefined, options);
  }