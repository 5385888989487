import React from 'react';
import './InfoPages.css';
import NotLoggedInMainLayout from '../layout/NotLoggedInMainLayout';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function FoundationPage() {
  usePageTitle('Replicant Trader | Foundation');

  return (
    <div className="InfoPage">
      <NotLoggedInMainLayout>
        <div className="body">
          <h1 className="page-title">Our Foundation</h1>
          <p className="page-description">
            Our foundation lies in research and innovation. Starting as a fat-finger detection project, Replicant Trader has evolved into a robust platform, making trading simulation accessible and realistic for everyone.
          </p>
        </div>
      </NotLoggedInMainLayout>
    </div>
  );
}
