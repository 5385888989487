import React from 'react';
import NotLoggedIn from '../components/navigationBars/notLoggedIn/NotLoggedIn';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

export default function NotLoggedInMainLayout({ children }) {
    return (
        <div className="NotLoggedInMainLayout">
            <NotLoggedIn />
            <div>{children}<Analytics /><SpeedInsights /></div>
        </div>
    );
}
