import React, { useEffect } from 'react';
import LogIn from "../components/authentication/logIn/LogIn";
import MainLayout from '../layout/NotLoggedInMainLayout';
import './LogInPage.css';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function LogInPage() {
    usePageTitle('Replicant Trader | Log In');

    return (
        <div className="LogIn">
            <MainLayout>
                <LogIn className="LogIn" />
            </MainLayout>
        </div>
    )
}
