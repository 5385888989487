export default function formatNumberWithCommas(number) {
    // Round the number to two decimal places and split into integer and decimal parts
    const [integerPart, decimalPart] = number.toFixed(2).split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the formatted integer part with the rounded decimal part
    return `${formattedIntegerPart}.${decimalPart}`;
}
