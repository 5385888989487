import { useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { firestoredb } from "../../../firebase";
import { convertTimestampToDate } from '../../utilities/clientQuote/Time';

const QuotesFetcher = ({ currentUser, setLiveQuotes, setOldQuotes, setLoading }) => {
    useEffect(() => {
        const quotesCollectionRef = collection(firestoredb, 'quotes', currentUser.uid, 'data');

        const unsubscribe = onSnapshot(quotesCollectionRef, (quotesSnapshot) => {
            let liveQuotesData = [];
            let oldQuotesData = [];

            quotesSnapshot.forEach((doc) => {
                const quoteData = {
                    id: doc.id,
                    ...doc.data(),
                    createdAt: convertTimestampToDate(doc.data().createdAt)
                };

                if (quoteData.status === 'active') {
                    liveQuotesData.push(quoteData);
                } else {
                    oldQuotesData.push(quoteData);
                }
            });

            liveQuotesData.sort((a, b) => b.createdAt - a.createdAt);
            oldQuotesData.sort((a, b) => b.createdAt - a.createdAt);

            setLiveQuotes(liveQuotesData);
            setOldQuotes(oldQuotesData);
            setLoading(false);
        }, error => {
            console.error('Error fetching quotes:', error);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [currentUser, setLiveQuotes, setOldQuotes, setLoading]);

    return null;
};

export default QuotesFetcher;
