import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { firestoredb } from '../../../firebase';
import { query, collection, where, getDocs } from 'firebase/firestore';
import defaultTheme from '../../utilities/styleUtilities/DefaultTheme';
import { useAuth } from '../authContext/AuthContext';
import { isValidEmail } from '../../utilities/validators/EmailValidator';
import { isValidPassword } from '../../utilities/validators/PasswordValidator';
import { isValidUsername } from '../../utilities/validators/UsernameValidator';
import './SignUp.css';

export default function SignUp() {
  const { signup } = useAuth(); // Get the signup function from AuthContext

  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    tradingRole: '',
    phoneNumber: '',
    username: '',
    picture: null,
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    tradingRole: '',
    phoneNumber: '',
    username: '',
    picture: '',
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'picture') {
      const file = files[0];
      if (file && file.size > 5 * 1024 * 1024) { // Check if the file size exceeds 5MB
        setErrors({ ...errors, picture: 'File size should not exceed 5MB' });
        return;
      } else {
        setErrors({ ...errors, picture: '' });
        setUserInput({ ...userInput, [name]: file });
      }
    } else {
      setUserInput({ ...userInput, [name]: value });
      validateInput(name, value);
    }
  };

  const validateInput = (name, value) => {
    let errorMessage = '';

    switch (name) {
      case 'firstName':
        errorMessage = value ? '' : 'Please enter your first name.';
        break;
      case 'lastName':
        errorMessage = value ? '' : 'Please enter your last name.';
        break;
      case 'email':
        errorMessage = isValidEmail(value)
          ? ''
          : 'Please enter a valid email address.';
        break;
      case 'password':
        errorMessage = isValidPassword(value)
          ? ''
          : 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
        break;
      case 'confirmPassword':
        errorMessage =
          value === userInput.password ? '' : 'Passwords do not match.';
        break;
      case 'tradingRole':
        errorMessage = value ? '' : 'Please select a trading role.';
        break;
      case 'phoneNumber':
        errorMessage = value ? '' : 'Please enter your phone number.';
        break;
      case 'username':
        errorMessage =
          isValidUsername(value)
            ? ''
            : 'Username must be 6–30 characters long, can contain letters, numbers, or periods and cannot contain special characters or more than one period in a row.';
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: errorMessage,
    });
  };

  const signUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Check if there are any validation errors
    if (Object.values(errors).some((error) => error !== '')) {
      setLoading(false);
      return;
    }

    try {
      // Check if the email already exists
      const emailQuery = query(collection(firestoredb, 'users'), where('email', '==', userInput.email));
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        setErrors((prevState) => ({ ...prevState, email: 'Email address already exists.' }));
        return;
      }

      // Check if the username already exists
      const usernameQuery = query(collection(firestoredb, 'users'), where('username', '==', userInput.username));
      const usernameSnapshot = await getDocs(usernameQuery);

      if (!usernameSnapshot.empty) {
        setErrors((prevState) => ({ ...prevState, username: 'Username already exists.' }));
        return;
      }

      // Call the signup function from AuthContext
      await signup(userInput.email, userInput.password, userInput);
      // Navigate or perform any other action after successful signup
    } catch (error) {
      // Handle authentication errors
      if (error.code === 'auth/email-already-in-use') {
        setErrors((prevState) => ({ ...prevState, email: 'Email address is already in use.' }));
      } else if (error.code === '400') {
        // Handle bad request error
        console.error('Bad Request:', error.message);
        setErrors((prevState) => ({ ...prevState, general: 'An error occurred. Please try again later.' }));
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            paddingTop: 2,
            paddingRight: 8,
            paddingBottom: 2,
            paddingLeft: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}
        >
          {loading ? ( // Render loading indicator if loading is true
            <CircularProgress />
          ) : (
            <>
              <Typography component="h1" variant="h5">
                CREATE AN ACCOUNT
              </Typography>
              <Box component="form" onSubmit={signUp} noValidate sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="firstName"
                    label="First Name"
                    autoFocus
                    value={userInput.firstName}
                    onChange={handleInputChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    value={userInput.lastName}
                    onChange={handleInputChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label="Email Address"
                    type="email"
                    autoComplete="email"
                    value={userInput.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    value={userInput.password}
                    onChange={handleInputChange}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    value={userInput.confirmPassword}
                    onChange={handleInputChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                  <FormControl margin="normal" fullWidth required error={!!errors.tradingRole}>
                    <InputLabel id="tradingRole-label">Trading Role</InputLabel>
                    <Select
                      labelId="tradingRole-label"
                      id="tradingRole"
                      name="tradingRole"
                      value={userInput.tradingRole}
                      label="Trading Role"
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Client">Client</MenuItem>
                      <MenuItem value="Market Maker">Market Maker</MenuItem>
                    </Select>
                    {errors.tradingRole && (
                      <Typography variant="body2" color="error">
                        {errors.tradingRole}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phoneNumber"
                    label="Phone Number"
                    value={userInput.phoneNumber}
                    onChange={handleInputChange}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="username"
                    label="Username"
                    value={userInput.username}
                    onChange={handleInputChange}
                    error={!!errors.username}
                    helperText={errors.username}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="picture"
                      onChange={handleInputChange}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" component="span" fullWidth>
                        Upload Profile Picture
                      </Button>
                    </label>
                    {errors.picture && (
                      <Typography color="error" variant="body2">
                        {errors.picture}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  SUBMIT
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
