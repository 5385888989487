import { useEffect } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { firestoredb } from "../../../firebase";
import { calculateRemainingTime } from '../../utilities/clientQuote/Time';

const QuoteExpirationHandler = ({ liveQuotes, setLiveQuotes, setOldQuotes, currentUser }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            setLiveQuotes((prevLiveQuotes) => {
                return prevLiveQuotes.map((quote) => {
                    const remainingTime = calculateRemainingTime(quote.createdAt, quote.validFor);

                    if (remainingTime <= 0) {
                        const quoteDocRef = doc(firestoredb, 'quotes', currentUser.uid, 'data', quote.id);
                        updateDoc(quoteDocRef, { status: 'expired' });

                        setOldQuotes((prevOldQuotes) => [quote, ...prevOldQuotes]);
                        return null;
                    }

                    return { ...quote, remainingTime };
                }).filter(quote => quote !== null);
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [liveQuotes, setLiveQuotes, setOldQuotes, currentUser]);

    return null;
};

export default QuoteExpirationHandler;
