import twentyFourHourTimeFormat from "../format/TwentyFourHourTimeFormat";

// Function to format date based on whether it's today, yesterday, or another date
const listFormatDate = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return twentyFourHourTimeFormat(date); // Show only time if today
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday"; // Show "Yesterday" for the day before
    } else {
      // Show the full date if it's neither today nor yesterday
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

export { listFormatDate };