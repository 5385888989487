import { useEffect, useState, useCallback } from 'react';
import { collection, onSnapshot, query, orderBy, Timestamp } from 'firebase/firestore';
import { firestoredb } from '../../../firebase';
import debounce from 'lodash/debounce';

const CACHE_KEY = 'positions_cache';
const CACHE_EXPIRATION_TIME = 3600000; // 1 hour in milliseconds

const getCache = (userId) => {
    const cache = localStorage.getItem(`${CACHE_KEY}_${userId}`);
    if (!cache) return null;

    const { data, timestamp } = JSON.parse(cache);
    if (Date.now() - timestamp > CACHE_EXPIRATION_TIME) {
        return null; // Cache is stale
    }
    return data;
};

const setCache = (userId, data) => {
    localStorage.setItem(`${CACHE_KEY}_${userId}`, JSON.stringify({
        data,
        timestamp: Date.now()
    }));
};

const PositionsFetcher = ({ currentUser, alignment, setPositions }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const memoizedSetPositions = useCallback(setPositions, []);

    const handleFirestoreUpdate = debounce((positionsData) => {
        memoizedSetPositions(positionsData);
        setCache(currentUser.uid, positionsData); // Update cache
    }, 500); // Debounce delay in milliseconds

    useEffect(() => {
        if (alignment === 'positions') {
            const cachedData = getCache(currentUser.uid);
            if (cachedData) {
                memoizedSetPositions(cachedData);
                setLoading(false);
            }

            const positionsCollectionRef = collection(firestoredb, 'positions', currentUser.uid, 'stocks');
            const q = query(positionsCollectionRef, orderBy('createdAt', 'desc'));

            const unsubscribe = onSnapshot(q, (positionsSnapshot) => {
                let positionsData = [];
                positionsSnapshot.forEach((doc) => {
                    const data = doc.data();
                    const createdAt = data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt);

                    positionsData.push({
                        id: doc.id,
                        stockTicker: doc.id,
                        position: data.position,
                        createdAt: createdAt.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    });
                });

                handleFirestoreUpdate(positionsData); // Update state and cache with new data
                setLoading(false);
            }, error => {
                console.error('Error fetching positions:', error);
                setError('Failed to fetch positions.');
                setLoading(false);
            });

            return () => unsubscribe();
        } else {
            setLoading(false); // Not in 'positions' view
        }
    }, [alignment, currentUser.uid, memoizedSetPositions]);

    // Optionally render error message or loading state
    return (
        <>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
        </>
    );
};

export default PositionsFetcher;
