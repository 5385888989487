import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { firestoredb } from '../../../firebase';
import { Box, Table, TableHead, TableBody, TableContainer, TableRow, Paper, TableCell, Typography } from '@mui/material';
import formatNumberWithCommas from '../format/FormatNumberWithCommas';

export default function TransactionsList({ userId }) {
    const [transactions, setTransactions] = useState([]);
    const CACHE_EXPIRY_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

    useEffect(() => {
        const transactionsCollectionRef = collection(firestoredb, 'transactions', userId, 'transactionDetails');
        const transactionsQuery = query(transactionsCollectionRef, orderBy('createdAt', 'desc'));

        const loadCachedData = () => {
            const cachedTransactions = JSON.parse(localStorage.getItem(`transactions_${userId}`));
            const cacheTimestamp = localStorage.getItem(`transactions_${userId}_timestamp`);

            if (cachedTransactions && cacheTimestamp) {
                const now = new Date().getTime();
                if (now - cacheTimestamp < CACHE_EXPIRY_TIME) {
                    // Convert 'createdAt' from string back to Date object
                    const transactionsData = cachedTransactions.map(transaction => ({
                        ...transaction,
                        createdAt: new Date(transaction.createdAt),
                    }));
                    setTransactions(transactionsData);
                    return true;
                } else {
                    localStorage.removeItem(`transactions_${userId}`);
                    localStorage.removeItem(`transactions_${userId}_timestamp`);
                }
            }
            return false;
        };

        const handleNewData = (transactionsSnapshot) => {
            let transactionsData = [];
            transactionsSnapshot.forEach((doc) => {
                transactionsData.push({
                    id: doc.id,
                    amount: doc.data().moneyDifference,
                    position: doc.data().positionsDifference,
                    createdAt: doc.data().createdAt.toDate(), // Convert Firestore timestamp to Date object
                });
            });

            transactionsData.sort((a, b) => b.createdAt - a.createdAt);
            setTransactions(transactionsData);

            const dataSize = new Blob([JSON.stringify(transactionsData)]).size;
            if (dataSize < 5000000) { // 5MB limit
                localStorage.setItem(`transactions_${userId}`, JSON.stringify(transactionsData));
                localStorage.setItem(`transactions_${userId}_timestamp`, new Date().getTime());
            } else {
                console.warn('Data too large to cache in localStorage');
            }
        };

        const isCacheLoaded = loadCachedData();

        const unsubscribe = onSnapshot(transactionsQuery, (transactionsSnapshot) => {
            handleNewData(transactionsSnapshot);
        }, error => {
            console.error('Error fetching transactions:', error);
        });

        return () => unsubscribe();
    }, [userId]);

    return (
        <Box>
            {transactions.length > 0 ? (
                <TableContainer component={Paper} sx={{ maxHeight: 330, overflowY: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#1976D2', borderRadius: '4px' }, '&::-webkit-scrollbar-track': { backgroundColor: '#D9D9D9' } }}>
                    <Table sx={{ maxWidth: 500 }} size="small" aria-label="transactions-table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">Amount</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">Position</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell align="left">{formatNumberWithCommas(transaction.amount)}</TableCell>
                                    <TableCell align="left">{transaction.position}</TableCell>
                                    <TableCell align="left">{transaction.createdAt.toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography align="center">No Transactions Available</Typography>
            )}
        </Box>
    );
}
