import React from 'react';
import './InfoPages.css';
import NotLoggedInMainLayout from '../layout/NotLoggedInMainLayout';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';
import { Typography } from '@mui/material';

export default function AboutUsPage() {
  usePageTitle('Replicant Trader | About Us');

  return (
    <div className="InfoPage">
      <NotLoggedInMainLayout>
        <div className="body">
          <Typography
            variant="h5"
            sx={{
              mb: 1,
              fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.4rem' }, // Responsive font size
            }}
          >
            About BEC
          </Typography>
          <Typography
            variant="body1"
            sx={{
              opacity: 0.8,
              maxWidth: '800px',
              mx: 'auto',
              mb: 1,
              fontSize: { xs: '0.8rem', sm: '1.4rem', md: '1.2rem' }, // Responsive font size
            }}
          >
            Replicant Trader began as a research project aimed at detecting and preventing fat-finger errors. It has since evolved into a comprehensive trading simulation platform, offering users hands-on experience with the NSE and NYSE, empowering them with real-world trading skills.
          </Typography>

          {/* Vision */}
          <Typography
            variant="h5"
            sx={{
              mt: 4,
              mb: 1,
              fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.4rem' }, // Responsive font size
            }}
          >
            Our Vision
          </Typography>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              maxWidth: '600px',
              mx: 'auto',
              fontSize: { xs: '0.8rem', sm: '1.4rem', md: '1.2rem' }, // Responsive font size
            }}
          >
            Our mission is to democratize access to high-quality trading simulations and financial tools, enabling users to build trading confidence, understand market dynamics, and enhance their strategies. We are committed to fostering an innovative environment where traders, both novice and experienced, can learn, practice, and refine their skills risk-free.
          </Typography>

          {/* Mission */}
          <Typography
            variant="h5"
            sx={{
              mt: 4,
              mb: 1,
              fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.4rem' }, // Responsive font size
            }}
          >
            Our Mission
          </Typography>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              maxWidth: '600px',
              mx: 'auto',
              fontSize: { xs: '0.8rem', sm: '1.4rem', md: '1.2rem' }, // Responsive font size
            }}
          >
            We aim to transform financial education and trading practices by offering an advanced, realistic, and interactive platform. Our vision is to empower users to master trading skills and make informed financial decisions, setting new standards in financial literacy and skill-building.
          </Typography>
        </div>
      </NotLoggedInMainLayout>
    </div>
  );
}
