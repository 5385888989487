import React from 'react';
import LoggedIn from '../components/navigationBars/loggedIn/LoggedIn';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function LoggedInMainLayout({ children }) {
    return (
        <div className="LoggedInMainLayout">
            <LoggedIn />
            <div style={{ marginTop: '0.2%' }}>
                {children}
                <Analytics />
                <SpeedInsights />
            </div>
        </div>
    );
}
