import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, CircularProgress, Badge, Typography, Tooltip } from '@mui/material';
import axios from 'axios';

export default function KenyaEquitiesFinancialInstrumentTracker() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSorted, setIsSorted] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc'); // State to track sort order
    const [retryTimeout, setRetryTimeout] = useState(null);

    const url = 'https://nairobi-stock-exchange-nse.p.rapidapi.com/stocks';
    const options = {
        method: 'GET',
        url: url,
        headers: {
            'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_NSE_MM_KEY,
            'x-rapidapi-host': 'nairobi-stock-exchange-nse.p.rapidapi.com'
        }
    };

    const isMarketOpen = () => {
        const now = new Date();
        const day = now.getDay(); // 0 (Sunday) to 6 (Saturday)
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const openTime = new Date();
        openTime.setHours(9, 15, 0, 0); // 9:15 AM EAT
        const closeTime = new Date();
        closeTime.setHours(15, 30, 0, 0); // 3:30 PM EAT
        const isWeekday = day >= 1 && day <= 5; // Monday to Friday
        return isWeekday && now >= openTime && now <= closeTime;
    };

    useEffect(() => {
        const fetchData = async (retryCount = 0) => {
            try {
                const response = await axios.request(options);
                setData(response.data);
                localStorage.setItem('nseData', JSON.stringify(response.data));
                setIsLoading(false);
                setIsSorted(false);
                setError(null); // Clear any previous errors
            } catch (err) {
                if (retryCount < 5) { // Retry up to 5 times
                    setError(`An error occurred while fetching data. Retrying in ${2 ** retryCount} seconds...`);
                    const timeout = setTimeout(() => fetchData(retryCount + 1), 2 ** retryCount * 1000); // Exponential backoff
                    setRetryTimeout(timeout);
                } else {
                    setError('An error occurred while fetching data. Please check your network connection.');
                    setIsLoading(false);
                }
            }
        };

        const cachedData = localStorage.getItem('nseData');
        if (cachedData) {
            setData(JSON.parse(cachedData));
            setIsLoading(false);
        } else {
            fetchData();
        }

        if (isMarketOpen()) {
            fetchData(); // Fetch immediately if the market is open
            const interval = setInterval(fetchData, 60000); // Fetch data every minute
            return () => clearInterval(interval);
        } else {
            fetchData(); // Fetch once if the market is closed
        }

        return () => {
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        };
    }, []);

    // Use effect to handle initial sorting by volume descending
    useEffect(() => {
        if (!isSorted && data.length > 0) {
            const sortedData = [...data].sort((a, b) => {
                const volumeA = parseInt(a.volume.replace(/,/g, '') || '0', 10);
                const volumeB = parseInt(b.volume.replace(/,/g, '') || '0', 10);
                return volumeB - volumeA;
            });
            setData(sortedData);
            setIsSorted(true); // Ensure it doesn't re-sort unnecessarily
        }
    }, [data, isSorted]);

    const calculateChangePercentage = (change, price) => {
        return ((change / price) * 100).toFixed(2);
    };

    const sortByChange = () => {
        const sortedData = [...data].sort((a, b) => {
            const changeA = a.price && a.change ? parseFloat(calculateChangePercentage(a.change, a.price)) : null;
            const changeB = b.price && b.change ? parseFloat(calculateChangePercentage(b.change, b.price)) : null;
            const volumeA = a.volume ? parseFloat(a.volume) : null;
            const volumeB = b.volume ? parseFloat(b.volume) : null;

            // Handle missing volume and %change by ranking those rows last
            if ((volumeA === null || changeA === null) && (volumeB !== null && changeB !== null)) return 1; // A goes after B
            if ((volumeB === null || changeB === null) && (volumeA !== null && changeA !== null)) return -1; // B goes after A
            if ((volumeA === null || changeA === null) && (volumeB === null || changeB === null)) return 0; // Keep both in place if both missing

            if (sortOrder === 'desc') {
                // Descending order:
                // 1. Positive changes (largest to smallest)
                // 2. Zero changes
                // 3. Negative changes (least negative to most negative)

                if (changeA > 0 && changeB > 0) return changeB - changeA; // Sort positive changes largest to smallest
                if (changeA > 0) return -1; // Place positive changes first
                if (changeB > 0) return 1;  // Place positive changes first
                if (changeA === 0 && changeB === 0) return 0; // Keep zero values in place
                if (changeA === 0) return -1; // Place zero values after positive changes
                if (changeB === 0) return 1;  // Place zero values after positive changes
                return changeB - changeA; // Sort negative changes least negative to most negative
            } else {
                // Ascending order:
                // 1. Negative changes (most negative to least negative)
                // 2. Zero changes
                // 3. Positive changes (smallest to largest)

                if (changeA < 0 && changeB < 0) return changeA - changeB; // Sort negative changes most negative to least negative
                if (changeA < 0) return -1; // Place negative changes first
                if (changeB < 0) return 1;  // Place negative changes first
                if (changeA === 0 && changeB === 0) return 0; // Keep zero values in place
                if (changeA === 0) return -1; // Place zero values after negative changes
                if (changeB === 0) return 1;  // Place zero values after negative changes
                return changeA - changeB; // Sort positive changes smallest to largest
            }
        });

        setData(sortedData);
        setIsSorted(true);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc'); // Toggle sort order
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                <Typography variant="p" style={{ marginRight: '0.5rem', fontSize: '10px' }}>
                    {isMarketOpen() ? (
                        <Tooltip title="can delay up to 15 min" arrow>
                            <Typography variant="body2" style={{ marginRight: '0.5rem', fontSize: '10px' }}>
                                Live
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography variant="body2" style={{ marginRight: '0.5rem', fontSize: '10px' }}>
                            Closed
                        </Typography>
                    )}
                </Typography>
                <Badge
                    color={isMarketOpen() ? 'success' : 'error'}
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
            </div>
            <div className="kenya-equities-table-container">
                <TableContainer component={Paper} sx={{ maxHeight: '275px', overflowY: 'auto' }}>
                    <Table size="small" aria-label="Kenya Equities data table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">TICKER</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">NAME</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">PRICE</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">VOLUME</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }} align="left">CHANGE</TableCell>
                                <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1, cursor: 'pointer' }} align="left" onClick={sortByChange}>%CHANGE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow key={row.ticker}>
                                    <TableCell component="th" scope="row">
                                        {row.ticker}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell align="center">{row.price || '-'}</TableCell>
                                    <TableCell align="center">{row.volume || '-'}</TableCell>
                                    <TableCell align="center" style={{ color: row.change > 0 ? 'green' : row.change < 0 ? 'red' : 'black' }}>
                                        {row.change || '-'}
                                    </TableCell>
                                    <TableCell align="center" style={{ color: row.change > 0 ? 'green' : row.change < 0 ? 'red' : 'black' }}>
                                        {row.price && row.change ? `${calculateChangePercentage(row.change, row.price)}%` : '-'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Container>
    );
}
