import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import TimeDisplay from '../../utilities/TimeDisplay';
import { useAuth } from '../../authentication/authContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import formatNumberWithCommas from '../../utilities/format/FormatNumberWithCommas';

// Define CSS variables for the component
const styles = {
  root: {
    '--primary-background-color': '#0A192F',
    '--secondary-background-color': '#112240',
    '--primary-text-color': '#FFFFFF',
    '--primary-button-color': '#1F63E8',
  },
  appBar: {
    backgroundColor: 'var(--secondary-background-color)',
  },
  toolbar: {
    display: 'flex',
    height: '5vh',
    justifyContent: 'space-between',
    backgroundColor: 'var(--secondary-background-color)',
    padding: { xs: '0 8px', sm: '0 16px' }, // Responsive padding
  },
  link: {
    color: 'var(--primary-text-color)',
    textDecoration: 'none',
    padding: { xs: '20px', sm: '40px' }, // Adjust padding based on screen size
    marginLeft: '-8%',
  },
  menuList: {
    backgroundColor: 'var(--secondary-background-color)',
  },
  walletText: {
    fontSize: { xs: '0.9rem', sm: '1rem' },
    marginLeft: { xs: 1, sm: 2 },
    color: 'var(--primary-text-color)',
  },
  displayName: {
    fontWeight: 'bold',
    fontSize: { xs: '1rem', sm: '1.2rem' },
    color: 'var(--primary-text-color)',
  },
  emailText: {
    fontSize: { xs: '0.85rem', sm: '1rem' },
    color: 'var(--primary-text-color)',
  },
};

export default function LoggedIn() {
  const { currentUser, userData, logout } = useAuth();
  const navigate = useNavigate();

  const signOut = () => {
    logout();
  };

  const navigateToSettings = () => {
    navigate('/account-settings');
  };

  const navigateToProfile = () => {
    navigate('/account-profile');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEmailClick = (event) => {
    event.stopPropagation();
  };

  // Determine wallet balance label
  const walletBalanceLabel = userData?.tradingRole === 'Market Maker' ? 'Commissions' : 'Net Liquid';

  return (
    <div style={styles.root}>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link href="/" sx={styles.link}>
              Replicant Trader
            </Link>
            {/* Hide TimeDisplay on very small screens */}
            <TimeDisplay sx={{ display: { xs: 'none', sm: 'block' } }} />
            {userData && (
              <Typography sx={styles.walletText}>
                {walletBalanceLabel}: ${formatNumberWithCommas(userData.walletBalance)}
              </Typography>
            )}
          </div>
          <div>
            <Tooltip title="Account menu" sx={{ color: 'var(--primary-text-color)' }}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} src={userData?.profilePictureUrl} />
              </IconButton>
            </Tooltip>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                ...styles.menuList,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {userData && userData.displayName && (
              <div
                onClick={handleEmailClick}
                style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 1, paddingBottom: 1, cursor: 'default' }}
              >
                <span style={styles.displayName}>{userData.displayName}</span>
                <br />
                <span style={styles.emailText}>{currentUser.email}</span>
              </div>
            )}
            <Divider sx={{ backgroundColor: 'var(--primary-text-color)' }} />
            <MenuItem onClick={navigateToSettings}>
              <SettingsIcon sx={{ marginRight: '17px', marginLeft: '-5px', color: 'var(--primary-text-color)' }} />
              <span style={{ color: 'var(--primary-text-color)' }}>Settings</span>
            </MenuItem>
            <MenuItem onClick={navigateToProfile}>
              <ProfileIcon sx={{ marginRight: '17px', marginLeft: '-5px', color: 'var(--primary-text-color)' }} />
              <span style={{ color: 'var(--primary-text-color)' }}>Profile</span>
            </MenuItem>
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <Logout fontSize="small" sx={{ color: 'var(--primary-text-color)' }} />
              </ListItemIcon>
              <span style={{ color: 'var(--primary-text-color)' }}>Logout</span>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
