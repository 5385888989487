export const nseStockData = [
    { ticker: 'SCOM', name: 'Safaricom Plc' },
    { ticker: 'KCB', name: 'KCB Group' },
    { ticker: 'KEGN', name: 'KenGen Plc' },
    { ticker: 'KNRE', name: 'Kenya Re-Insurance Corporation Ltd' },
    { ticker: 'EQTY', name: 'Equity Group Holdings Limited' },
    { ticker: 'KPLC', name: 'Kenya Power & Lighting Company' },
    { ticker: 'NCBA', name: 'NCBA Group Plc' },
    { ticker: 'SMER', name: 'Sameer Africa Plc' },
    { ticker: 'COOP', name: 'Co-operative Bank of Kenya Limited' },
    { ticker: 'BAMB', name: 'Bamburi Cement Limited' },
    { ticker: 'TCL', name: 'Trans Century Limited' },
    { ticker: 'EVRD', name: 'Eveready East Africa Limited' },
    { ticker: 'CABL', name: 'East African Cables Limited' },
    { ticker: 'JUB', name: 'Jubilee Holdings Limited' },
    { ticker: 'DTK', name: 'Diamond Trust Bank Kenya Limited' },
    { ticker: 'CTUM', name: 'Centum Investment Company' },
    { ticker: 'IMH', name: 'I&M Holdings Plc' },
    { ticker: 'SCBK', name: 'Standard Chartered Bank Limited' },
    { ticker: 'BRIT', name: 'Britam Holdings Limited' },
    { ticker: 'CARB', name: 'Carbacid Investments Limited' },
    { ticker: 'ABSA', name: 'Absa Bank Kenya Plc' },
    { ticker: 'CIC', name: 'CIC Insurance Group Limited' },
    { ticker: 'HAFR', name: 'Home Afrika Limited' },
    { ticker: 'HFCK', name: 'HF Group Limited' },
    { ticker: 'BAT', name: 'British American Tobacco Kenya' },
    { ticker: 'UMME', name: 'Umeme Limited' },
    { ticker: 'NSE', name: 'Nairobi Securities Exchange Limited' },
    { ticker: 'SLAM', name: 'Sanlam Kenya Plc' },
    { ticker: 'SBIC', name: 'Stanbic Holdings Limited' },
    { ticker: 'LBTY', name: 'Liberty Kenya Holdings Limited' },
    { ticker: 'PORT', name: 'East African Portland Cement Co. Ltd' },
    { ticker: 'XPRS', name: 'Express Kenya Limited' },
    { ticker: 'UCHM', name: 'Uchumi Supermarket Limited' },
    { ticker: 'SCAN', name: 'ScanGroup Limited' },
    { ticker: 'NMG', name: 'Nation Media Group' },
    { ticker: 'KAPC', name: 'Kapchorua Tea Company Limited' },
    { ticker: 'OCH', name: 'Olympia Capital Holdings Limited' },
    { ticker: 'NBV', name: 'Nairobi Business Ventures Ltd' },
    { ticker: 'CRWN', name: 'Crown Paints Kenya Limited' },
    { ticker: 'EABL', name: 'East African Breweries Limited' },
    { ticker: 'FTGH', name: 'Flame Tree Group Holdings Limited' },
    { ticker: 'BOC', name: 'BOC Kenya Limited' },
    { ticker: 'SASN', name: 'Sasini Tea and Coffee Limited' },
    { ticker: 'SGL', name: 'Standard Group Limited' },
    { ticker: 'TOTL', name: 'Total Kenya Limited' },
    { ticker: 'TPSE', name: 'TPS Eastern Africa Serena Limited' },
    { ticker: 'WTK', name: 'Williamson Tea Kenya Limited' },
    { ticker: 'BKG', name: 'BK Group Plc' },
    { ticker: 'CGEN', name: 'Car and General Kenya Limited' },
    { ticker: 'DCON', name: 'Deacons East Africa Plc' },
    { ticker: 'EGAD', name: 'Eaagads Limited' },
    { ticker: 'GLD', name: 'Absa NewGold ETF' },
    { ticker: 'HBE', name: 'Homeboyz Entertainment' },
    { ticker: 'KPLC-P4', name: 'Kenya Power 4% Preference Shares' },
    { ticker: 'KPLC-P7', name: 'Kenya Power 7% Preference Shares' },
    { ticker: 'KQ', name: 'Kenya Airways Limited' },
    { ticker: 'KUKZ', name: 'Kakuzi Limited' },
    { ticker: 'KURV', name: 'Kurwitu Ventures Limited' },
    { ticker: 'LAPR', name: 'Laptrust Imara Income-REIT' },
    { ticker: 'LIMT', name: 'Limuru Tea Company Limited' },
    { ticker: 'LKL', name: 'Longhorn Publishers Limited' },
    { ticker: 'MSC', name: 'Mumias Sugar Company Limited' },
    { ticker: 'ORCH', name: 'Kenya Orchards Limited' },
    { ticker: 'UNGA', name: 'Unga Group Limited' },
    { ticker: 'HBE', name: 'Homeboyz Entertainment' },
];
