import React, { useEffect, useRef } from 'react';

export default function USTradingViewHistoricalPerformance() {
    const container = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;

        script.innerHTML = `
        {
          "width": "100%",
          "height": "100%",
          "symbol": "NASDAQ:TSLA",
          "interval": "1",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "withdateranges": true,
          "hide_side_toolbar": false,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
        container.current.appendChild(script);
    }, []);

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            <style jsx>{`
                .tradingview-widget-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    padding: 0px;
                }
                /* Ensure responsive sizing */
                @media (min-width: 1024px) {
                    .tradingview-widget-container {
                        max-width: 100%;
                    }
                }
                @media (min-width: 768px) and (max-width: 1023px) {
                    .tradingview-widget-container {
                        max-width: 100%;
                    }
                }
                @media (max-width: 767px) {
                    .tradingview-widget-container {
                        max-width: 100%;
                    }
                }
            `}</style>
        </div>
    );
}
