const usernameRegex = /^[a-zA-Z0-9.]{6,30}$/;

/**
 * Validates a username using a regular expression.
 * @param {string} username - The username to validate.
 * @returns {boolean} - Returns true if the username is valid, false otherwise.
 */
export const isValidUsername = (username) => {
    return usernameRegex.test(username) && !/[&=_\-' +[\]<>]/.test(username) && !/\.\./.test(username) && !/^\.|\.$/.test(username);
};
