import React from 'react';
import { Box, Grid } from '@mui/material';
import LoggedInMainLayout from '../layout/LoggedInMainLayout';
import FinancialInstrumentTracker from '../components/dashboards/marketMaker/FinancialInstrumentTracker';
import HistoricalPerformanceTracker from '../components/dashboards/marketMaker/HistoricalPerformance';
import Chat from '../components/dashboards/marketMaker/Chat';
import Quote from '../components/dashboards/marketMaker/Quote';
import News from '../components/dashboards/marketMaker/News';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function MarketMakerDashboardPage() {
    usePageTitle('Replicant Trader | Dashboard');

    // Define CSS variables for styling
    const rootStyles = {
        '--primary-background-color': '#0A192F',
        '--secondary-background-color': '#112240',
        '--primary-text-color': '#FFFFFF',
        '--primary-button-color': '#1F63E8',
    };

    return (
        <div
            style={{
                ...rootStyles,
                height: '100vh',
                backgroundColor: 'var(--primary-background-color)',
            }}
        >
            <LoggedInMainLayout>
                {/* Top section */}
                <Grid
                    container
                    spacing={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '0.2%',
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                minHeight: { xs: '40vh', md: '43vh' },
                                padding: 1,
                                borderRadius: 0,
                                flexGrow: 1,
                                backgroundColor: 'var(--secondary-background-color)',
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            <FinancialInstrumentTracker />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                minHeight: { xs: '40vh', md: '43vh' },
                                height: '100%',
                                padding: 1,
                                borderRadius: 0,
                                flexGrow: 1,
                                backgroundColor: 'var(--secondary-background-color)',
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            <HistoricalPerformanceTracker />
                        </Box>
                    </Grid>
                </Grid>

                {/* Bottom section */}
                <Grid
                    container
                    spacing={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid item xs={12} md={6} lg={4.5}>
                        <Box
                            sx={{
                                minHeight: { xs: '40vh', md: '48vh' },
                                padding: 1,
                                borderRadius: 0,
                                flexGrow: 1,
                                backgroundColor: 'var(--secondary-background-color)',
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            <Chat />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.5}>
                        <Box
                            sx={{
                                minHeight: { xs: '40vh', md: '48vh' },
                                padding: 1,
                                borderRadius: 0,
                                flexGrow: 1,
                                backgroundColor: 'var(--secondary-background-color)',
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            <News />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <Box
                            sx={{
                                minHeight: { xs: '40vh', md: '48vh' },
                                padding: 1,
                                borderRadius: 0,
                                flexGrow: 1,
                                backgroundColor: 'var(--secondary-background-color)',
                                color: 'var(--primary-text-color)',
                            }}
                        >
                            <Quote />
                        </Box>
                    </Grid>
                </Grid>
            </LoggedInMainLayout>
        </div>
    );
}
