import React from 'react';
import './NotLoggedIn.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from "@mui/material/Divider";

export default function NotLoggedIn() {
  const isMobile = useMediaQuery('(max-width:938px)');
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{ style: { height: '100%', backgroundColor: '#112240' } }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
        <IconButton
          onClick={toggleDrawer(false)}
          style={{ backgroundColor: 'red', borderRadius: '8px' }} // Soft corners
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider sx={{ backgroundColor: "white" }} />
      <List>
        <ListItem button component="a" href="/about">
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component="a" href="/what-we-do">
          <ListItemText primary="What we do" />
        </ListItem>
        <ListItem button component="a" href="/foundation">
          <ListItemText primary="Foundation" />
        </ListItem>
        <ListItem button component="a" href="/capital">
          <ListItemText primary="Capital" />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Link href="/">Replicant Trader</Link>
            <Link href="/login">Login</Link>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {drawer}
          </div>
        ) : (
          <>
            <div className="left">
              <Link href="/">Replicant Trader</Link>
            </div>
            <div className="center">
              <Link href="/about">About</Link>
              <Link href="/what-we-do">What we do</Link>
              <Link href="/foundation">Foundation</Link>
              <Link href="/capital">Capital</Link>
            </div>
            <div className="right">
              <Link href="/login">Login</Link>
            </div>
          </>
        )}
      </Toolbar>
      <div id="body">
        {/* Additional content can go here */}
      </div>
    </AppBar>
  );
}