import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { addDoc, collection, serverTimestamp, doc, onSnapshot, getDocs, updateDoc } from 'firebase/firestore';
import { firestoredb } from '../../../firebase';
import { useAuth } from '../../authentication/authContext/AuthContext';
import defaultTheme from '../../utilities/styleUtilities/DefaultTheme';
import { useSelectedClient } from './SelectedClientContext';
import { auth } from '../../../firebase';
import { nyseStockData } from '../../utilities/dataSource/NYSEStockData';
import { nseStockData } from '../../utilities/dataSource/NSEStockData';
// import formatNumberWithCommas from '../../utilities/format/FormatNumberWithCommas';
// import { IncrementDecrementInput, ValidForIncrementDecrementInput, VolumeIncrementDecrementInput } from '../../utilities/format/IncrementDecrementInput';
import { restClient } from '@polygon.io/client-js';
import TransactionsList from '../../utilities/clientQuote/TransactionsList';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PositionsFetcher from '../../utilities/clientQuote/PositionsFetcher';
import { TableContainer, TableHead, TableBody, TableCell, TableRow, Table, Paper } from '@mui/material';

const initialState = {
    stockTicker: '',
    bid: '',
    offer: '',
    volume: '',
    validFor: '',
    error: '',
    stockPrice: null,
};

export default function Quote() {
    const [formData, setFormData] = useState(initialState);
    const [inputValue, setInputValue] = useState(''); // State to control Autocomplete input value
    const { currentUser } = useAuth();
    const { selectedClientId } = useSelectedClient();
    const [open, setOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const key = process.env.REACT_APP_POLYGONIO_CLIENT_HP_KEY;
    const [alignment, setAlignment] = useState(() => {
        const savedAlignment = localStorage.getItem('alignment');
        return savedAlignment ? JSON.parse(savedAlignment) : 'positions';
    });
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [positions, setPositions] = useState([]);

    // Save the alignment state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('alignment', JSON.stringify(alignment));
    }, [alignment]);

    const fetchStockPrice = async (ticker) => {
        const options = {
            method: 'GET',
            url: `https://nairobi-stock-exchange-nse.p.rapidapi.com/stocks/${ticker}`,
            headers: {
                'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_NSE_MM_KEY,
                'x-rapidapi-host': 'nairobi-stock-exchange-nse.p.rapidapi.com'
            }
        };
        try {
            const response = await axios.request(options);
            // console.log(response);
            if (response.data && response.data.length > 0) {
                // console.log(response.data[0].price); // Log the price to check the structure
                return parseFloat(response.data[0].price); // Return the price as a float
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const fetchNYSEStockPrice = async (ticker) => {
        const rest = restClient(key);
        try {
            const response = await rest.stocks.previousClose(ticker);
            // console.log(response);
            if (response.results && response.results.length > 0) {
                return response.results[0].c;
            }
            return null;
        } catch (error) {
            console.error('An error happened:', error);
            return null;
        }
    };

    const handleFormChange = async (event) => {
        const { name, value } = event.target;
        const numericValue = Number(value);

        if ((name === 'bid' || name === 'offer' || name === 'volume' || name === 'validFor') && numericValue <= 0) {
            setFormData({ ...formData, [name]: '', error: 'Value must be greater than zero.' });
            return;
        }

        let error = '';

        if ((name === 'bid' || name === 'offer') && formData.stockPrice) {
            const percentageDifference = Math.abs(numericValue - formData.stockPrice) / formData.stockPrice * 100;
            if (percentageDifference > 10) {
                error = 'Fat finger error detected (+-10%)';
            }
        }

        setFormData({ ...formData, [name]: numericValue, error });
    };

    const validateInputs = () => {
        const { stockTicker, bid, offer, volume, validFor } = formData;

        if (!stockTicker || !bid || !offer || !volume || !validFor) {
            setFormData({ ...formData, error: 'All fields are required.' });
            return false;
        }

        if (!selectedClientId) {
            setFormData({ ...formData, error: 'No client selected.' });
            return false;
        }

        setIsButtonDisabled(false);
        return true;
    };

    const handleClickOpen = () => {
        if (validateInputs()) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendQuote = async () => {
        const { stockTicker, bid, offer, volume, validFor } = formData;

        try {
            const quotesCollectionRef = collection(firestoredb, 'quotes', selectedClientId, 'data');

            await addDoc(quotesCollectionRef, {
                stockTicker,
                bid,
                offer,
                volume,
                validFor,
                status: 'active',
                createdAt: new Date(),
                createdBy: currentUser.displayName,
                creatorUserUId: currentUser.uid,
            });

            // console.log('Document written successfully');

            const roomId = [auth.currentUser.uid, selectedClientId].sort().join("_");
            const roomDocRef = doc(collection(firestoredb, "chats"), roomId);
            const messagesSubCollectionRef = collection(roomDocRef, "messages");

            await addDoc(messagesSubCollectionRef, {
                text: `Quote sent: ${stockTicker}, Bid: ${bid}, Offer: ${offer}, Volume: ${volume}, Valid for: ${validFor}, Status: active`,
                email: currentUser.email,
                createdAt: serverTimestamp(),
                uid: auth.currentUser.uid,
                read: false,
            });

            setFormData(initialState);
            setInputValue(''); // Clear the Autocomplete input value
            setOpen(false);
        } catch (error) {
            console.error('Error adding document: ', error);
            setFormData({ ...formData, error: `An error occurred while saving data: ${error.message}` });
            setOpen(false);
        }
    };

    useEffect(() => {
        if (selectedClientId) {
            const quotesCollectionRef = collection(firestoredb, 'quotes', selectedClientId, 'data');

            const unsubscribe = onSnapshot(quotesCollectionRef, (snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    if (change.type === "modified") {
                        const { stockTicker, status } = change.doc.data();
                        const roomId = [auth.currentUser.uid, selectedClientId].sort().join("_");
                        const roomDocRef = doc(collection(firestoredb, "chats"), roomId);
                        const messagesSubCollectionRef = collection(roomDocRef, "messages");

                        const messagesSnapshot = await getDocs(messagesSubCollectionRef);
                        messagesSnapshot.forEach((doc) => {
                            if (doc.data().text.includes(stockTicker)) {
                                updateDoc(doc.ref, {
                                    text: doc.data().text.replace(/Status: \w+/, `Status: ${status}`),
                                    updatedAt: serverTimestamp(),
                                });
                            }
                        });
                    }
                });
            });

            return () => unsubscribe();
        }
    }, [selectedClientId]);

    const combinedStockData = [...nyseStockData, ...nseStockData];

    const handleToggleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <form onSubmit={(e) => { e.preventDefault(); handleClickOpen(); }} noValidate>
                    <Box sx={{ display: 'flex' }}>
                        <Autocomplete
                            freeSolo
                            options={combinedStockData}
                            getOptionLabel={(option) => `${option.ticker}`}
                            value={formData.stockTicker ? combinedStockData.find(stock => stock.ticker === formData.stockTicker) : null}
                            inputValue={inputValue}
                            onChange={async (event, newValue) => {
                                const stockTicker = newValue?.ticker || '';
                                setFormData({ ...formData, stockTicker, error: '' });
                                setInputValue(stockTicker); // Update inputValue state

                                let stockPrice = null;
                                let currencySymbol = '$';

                                if (nseStockData.some(stock => stock.ticker === stockTicker)) {
                                    stockPrice = await fetchStockPrice(stockTicker);
                                    currencySymbol = 'Ksh';
                                } else if (nyseStockData.some(stock => stock.ticker === stockTicker)) {
                                    stockPrice = await fetchNYSEStockPrice(stockTicker);
                                }

                                setFormData({ ...formData, stockTicker, stockPrice, bid: stockPrice, offer: stockPrice });
                                setCurrencySymbol(currencySymbol);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue); // Update inputValue state on input change
                            }}
                            renderOption={(props, option) => (
                                <li {...props} style={{ color: '#112240' }}>
                                    {option.ticker} - {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <Tooltip title="Enter the stock ticker symbol or company name">
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        required
                                        id="stock-ticker-input"
                                        label="Stock Ticker"
                                        name="stockTicker"
                                        autoComplete="off"
                                        sx={{ margin: 1, width: '200px' }}
                                    />
                                </Tooltip>
                            )}
                        />
                        <TextField
                            margin="normal"
                            required
                            id="valid-for"
                            label="Valid for"
                            name="validFor"
                            type="number"
                            autoComplete="off"
                            sx={{ margin: 1 }}
                            value={formData.validFor}
                            onChange={handleFormChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            id="volume"
                            label="Volume"
                            name="volume"
                            type="number"
                            autoComplete="off"
                            sx={{ margin: 1 }}
                            value={formData.volume}
                            onChange={handleFormChange}
                        />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            margin="normal"
                            required
                            id="bid"
                            label={`Bid (${currencySymbol})`}
                            name="bid"
                            type="number"
                            autoComplete="off"
                            sx={{ margin: 1 }}
                            value={formData.bid}
                            onChange={handleFormChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            id="offer"
                            label={`Offer (${currencySymbol})`}
                            name="offer"
                            type="number"
                            autoComplete="off"
                            sx={{ margin: 1 }}
                            value={formData.offer}
                            onChange={handleFormChange}
                        />
                    </Box>
                    {formData.error && (
                        <Typography variant="body2" color="error" sx={{ margin: 1 }}>
                            {formData.error}
                        </Typography>
                    )}
                    <Box sx={{ width: '55%' }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            SEND QUOTE
                        </Button>
                    </Box>
                </form>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ background: '#0A192F', color: '#FFFFFF' }}>
                        {"Confirm Quote Details"}
                    </DialogTitle>
                    <DialogContent sx={{ background: '#0A192F' }}>
                        <DialogContentText id="alert-dialog-description" sx={{ color: '#FFFFFF' }}>
                            Please confirm the quote details before sending:
                        </DialogContentText>
                        <Typography color="#64ffda">Stock Ticker: {formData.stockTicker}</Typography>
                        <Typography color="#64ffda">Bid: {currencySymbol}{formData.bid}</Typography>
                        <Typography color="#64ffda">Offer: {currencySymbol}{formData.offer}</Typography>
                        <Typography color="#64ffda">Volume: {formData.volume}</Typography>
                        <Typography color="#64ffda">Valid For: {formData.validFor}</Typography>
                    </DialogContent>
                    <DialogActions sx={{ background: '#0A192F' }}>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={sendQuote} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleToggleChange}
                aria-label="Quotes"
            >
                <ToggleButton value="positions" sx={{ color: '#FFFFFF' }}>Positions</ToggleButton>
                <ToggleButton value="transactions" sx={{ color: '#FFFFFF' }}>Transactions</ToggleButton>
            </ToggleButtonGroup>
            <PositionsFetcher
                currentUser={currentUser}
                alignment={alignment}
                setPositions={setPositions}
            />
            {alignment === 'positions' && (
                positions.length > 0 ? (
                    <TableContainer component={Paper} sx={{ maxHeight: 150, overflowY: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#1976D2', borderRadius: '4px' }, '&::-webkit-scrollbar-track': { backgroundColor: '#D9D9D9' } }}>
                        <Table sx={{ maxWidth: 500 }} size="small" aria-label="positions-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1, align: 'left' }} align="left">Stock Ticker</TableCell>
                                    <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1, align: 'left' }} align="left">Position</TableCell>
                                    <TableCell sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1, align: 'left' }} align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {positions.map((position) => (
                                    <TableRow key={position.id}>
                                        <TableCell align="left">{position.stockTicker}</TableCell>
                                        <TableCell align="left">{position.position}</TableCell>
                                        <TableCell align="left">{position.createdAt}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography align="center">No Positions Available</Typography>
                )
            )}
            {alignment === 'transactions' && (
                <div style={{ height: '150px', overflowY: 'auto' }}>
                    <TransactionsList userId={currentUser.uid} />
                </div>
            )}
        </ThemeProvider>
    );
}
