import React, { useState, useEffect } from 'react';
import CustomDataChart from '../client/CustomDataChart';
import USTradingViewHistoricalPerformance from './USTradingViewHistoricalPerformance';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import defaultTheme from '../../utilities/styleUtilities/DefaultTheme';
import { Box, Grid } from '@mui/material';

export default function HistoricalPerformance() {
    const [selectedWidget, setSelectedWidget] = useState('tradingView');

    useEffect(() => {
        const savedWidget = localStorage.getItem('selectedWidget');
        if (savedWidget) {
            setSelectedWidget(savedWidget);
        }
    }, []);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedWidget(newValue);
        localStorage.setItem('selectedWidget', newValue);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <FormControl sx={{ mb: 1, minWidth: '100%' }} size="small">
                    <InputLabel id="select-widget-label">Widget</InputLabel>
                    <Select
                        labelId="select-widget-label"
                        id="select-widget"
                        value={selectedWidget}
                        label="Widget"
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#112240',
                                    color: 'white',
                                },
                            },
                        }}
                    >
                        <MenuItem
                            value="tradingView"
                            sx={{ backgroundColor: '#112240', color: 'white' }}
                        >
                            TradingView Chart
                        </MenuItem>
                        <MenuItem
                            value="customData"
                            sx={{ backgroundColor: '#112240', color: 'white' }}
                        >
                            Custom Data Chart
                        </MenuItem>
                    </Select>
                </FormControl>

                <Grid container sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{ width: '100%', height: '100%' }}>
                            {selectedWidget === 'tradingView' && (
                                <USTradingViewHistoricalPerformance sx={{ width: '100%', height: '100%' }} />
                            )}
                            {selectedWidget === 'customData' && (
                                <CustomDataChart sx={{ width: '100%', height: '100%' }} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
