export const nyseStockData = [
    { ticker: 'AAPL', name: 'Apple Inc' },
    { ticker: 'MSFT', name: 'Microsoft Corporation' },
    { ticker: 'GOOGL', name: 'Alphabet Inc' },
    { ticker: 'AMZN', name: 'Amazon.com Inc' },
    { ticker: 'TSLA', name: 'Tesla Inc' },
    { ticker: 'NVDA', name: 'Nvidia' },
    { ticker: 'META', name: 'META Platforms Inc' },
    { ticker: 'NFLX', name: 'Netflix Inc' },
    { ticker: 'IBM', name: 'International Bus Mach Corp' },
    { ticker: 'JPM', name: 'JPMorgan Chase & Co' },
    { ticker: 'TSM', name: 'Taiwan Semiconductor Manufacturing Spon Ads Each Rep 5 Ord TWD10' },
    { ticker: 'ARM', name: 'Arm Holdings PLC Spon Ads Each Rep 1 Ord SHS' },
    { ticker: 'RDDT', name: 'Reddit Inc' },
    { ticker: 'WMT', name: 'Walmart Inc' },
    { ticker: 'BA', name: 'Boeing Co' },
    { ticker: 'BAC', name: 'Bank of America Corporation' },
    { ticker: 'C', name: 'Citigroup Inc' },
    { ticker: 'DIS', name: 'Walt Disney Company' },
    { ticker: 'MS', name: 'Morgan Stanley' },
    { ticker: 'UL', name: 'Unilever Plc Ads-EA Repr 1 Ord GBP0.0311' },
    { ticker: 'NEE', name: 'Nextera Energy Inc' },
    { ticker: 'UNP', name: 'Union Pacific Corp' },
    { ticker: 'SPGI', name: 'S&P Global Inc' },
    { ticker: 'HDFC', name: 'HDFC Bank Ltd Spon Ads Each Rep 3 Ord SHS' },
    { ticker: 'GS', name: 'Goldman Sachs Group Inc' },
    { ticker: 'CMCSA', name: 'Comcast Corp' },
    { ticker: 'HSBC', name: 'HSBC Holdings PLC Adr Each Repr 5 Ord USD0.50' },
    { ticker: 'TTE', name: 'Total Energies SE Spon Ads EA Rep 1 Ord SHS' },
    { ticker: 'ISRG', name: 'Intuitive Surgical Inc' },
    { ticker: 'PM', name: 'Philip Morris International Inc' },
    { ticker: 'PFE', name: 'Pfizer Inc' },
    { ticker: 'CAT', name: 'Caterpillar Inc' },
    { ticker: 'AXP', name: 'American Express Co' },
    { ticker: 'VZ', name: 'Verizon Communications' },
    { ticker: 'AMAT', name: 'Applied Materials Inc' },
    { ticker: 'AMGN', name: 'Amgen Inc' },
    { ticker: 'ABT', name: 'Abbott Labaratories' },
    { ticker: 'INTU', name: 'Intuit Inc' },
    { ticker: 'DHR', name: 'Danaher Corp' },
    { ticker: 'BABA', name: 'Alibaba Group Holding LTD SPON ADS Each Rep 8 Ord SHS' },
    { ticker: 'TXN', name: 'Texas Instruments Inc' },
    { ticker: 'MCD', name: "McDonald's Corporation" },
    { ticker: 'CSCO', name: 'Cisco Systems Inc' },
    { ticker: 'WFC', name: 'Wells Fargo & Company' },
    { ticker: 'TMO', name: 'Thermo Fisher Scientific Inc' },
    { ticker: 'ACN', name: 'Accenture PLC' },
    { ticker: 'QCOM', name: 'Qualcomm Inc' },
    { ticker: 'LIN', name: 'Linde PLC' },
    { ticker: 'TMUS', name: 'T-Mobile US Inc' },
    { ticker: 'NVS', name: 'Novarties AG ADR-EACH REPR 1 CHF0.5(REGD)' },
    { ticker: 'SHEL', name: 'Shell PLC SPON Ads EA Rep 2 Ord SHS' },
    { ticker: 'PEP', name: 'Pepsico Inc' },
    { ticker: 'SAP', name: 'SAP SE SPON Adr Eeach Rep 1 Ord NPV' },
    { ticker: 'CRM', name: 'Salesforce Inc' },
    { ticker: 'ADBE', name: 'Adobe Inc' },
    { ticker: 'AMD', name: 'Advanced Micro Devices Inc' },
    { ticker: 'AZN', name: 'Astrazeneca PLC SPON Adr Each Rep 0.50 Ord SHS' },
    { ticker: 'TM', name: 'Toyota Motor Corp Adr-Each Rep 10 Ord NPV LVL' },
    { ticker: 'CO', name: 'Coca-Cola Co' },
    { ticker: 'CVX', name: 'Chevron Corporation' },
    { ticker: 'ABBV', name: 'Abbvie Inc' },
    { ticker: 'MRK', name: 'Merck & Co Inc' },
    { ticker: 'HD', name: 'Home Depot Inc' },
    { ticker: 'ASML', name: 'ASML Holding NV Euro.09 NY Registry SHS 2012' },
    { ticker: 'JNJ', name: 'Johnson & Johnson' },
    { ticker: 'COST', name: 'Costco Wholesale Corp' },
    { ticker: 'ORCL', name: 'Oracle Corp' },
    { ticker: 'PG', name: 'Procter & Gamble CO' },
    { ticker: 'MA', name: 'Mastercard Incorporated' },
    { ticker: 'TME', name: 'Tencent Music Entertainment Group SPON Ads Each Repr 2 Ord SHS Cl A' },
    { ticker: 'UNH', name: 'UnitedHealth Group Inc' },
    { ticker: 'XOM', name: 'Exxon Mobil Corporation' },
    { ticker: 'V', name: 'Visa Inc' },
    { ticker: 'NVO', name: "Novo Nordisk A/S Adr-Each CNV Into 1 Class 'B'DKK1" },
    { ticker: 'AVGO', name: 'Broadcom Inc' },
    { ticker: 'LLY', name: 'Eli Lilly and Company' },
    { ticker: 'BRK.A', name: 'Berkshire Hathaway Inc' },
    { ticker: 'BRK.B', name: 'Berkshire Hathaway Inc' },
    { ticker: 'MARA', name: 'Marathon Digital Holdings Inc' },
    { ticker: 'UBER', name: 'Uber Technologies Inc' },
    { ticker: 'CRWD', name: 'CrowdStrike Holdings Inc' },
    { ticker: 'INTC', name: 'Intel Corp' },
    { ticker: 'SMCI', name: 'Super Micro Computer Inc' },
    { ticker: 'PLTR', name: 'Palantir Tech Inc' },
    { ticker: 'DELL', name: 'Dell Technologies Inc' },
    { ticker: 'PINS', name: 'Pinterest Inc' },
    { ticker: 'T', name: 'AT&T Inc' },
];
