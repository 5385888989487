const fetchExchangeRate = async () => {
  const baseCurrency = 'KES';
  const apiKey = process.env.REACT_APP_CURRENCY_EXCHANGE_RATE_API_KEY;

  // Retrieve cached data and last fetch date
  const cachedData = localStorage.getItem('exchangeRateData');
  const lastFetchDate = localStorage.getItem('lastFetchDate');

  // Get current date
  const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD

  // Check if cached data is available and still valid
  if (cachedData && lastFetchDate === today) {
    return JSON.parse(cachedData).conversion_rates.USD; // Return cached rate
  }

  // Fetch new data if cache is missing or outdated
  const response = await fetch(`https://v6.exchangerate-api.com/v6/${apiKey}/latest/${baseCurrency}`);
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Error fetching exchange rate: ${errorData.error_type}`);
  }
  const data = await response.json();

  // Cache the fetched data and update last fetch date
  localStorage.setItem('exchangeRateData', JSON.stringify(data));
  localStorage.setItem('lastFetchDate', today);

  return data.conversion_rates.USD; // Return the conversion rate to USD
};

export { fetchExchangeRate };
