import React from 'react';
import './InfoPages.css';
import NotLoggedInMainLayout from '../layout/NotLoggedInMainLayout';
import usePageTitle from '../components/utilities/dynamicPageTitle/usePageTitle';

export default function CapitalPage() {
  usePageTitle('Replicant Trader | Capital');

  return (
    <div className="InfoPage">
      <NotLoggedInMainLayout>
        <div className="body">
          <h1 className="page-title">Capital</h1>
          <p className="page-description">
            Our simulated trading capital of $50M enables users to experience the financial market in a controlled setting. Through Replicant Trader, users can learn effective capital management and refine their investment skills.
          </p>
        </div>
      </NotLoggedInMainLayout>
    </div>
  );
}
